<!-- <div class="container text-center">
  <h1>Live Video</h1>
</div>

<div class="container">
  <iframe src="https://player.vimeo.com/video/426793354" width="1110" height="625" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
</div>

<div class="container mt-4">
  <button class="btn btn-primary w-100" onclick="window.open('https://tawk.to/chat/5edfae4b4a7c6258179a4179/1eafbqbtv','name','width=500,height=500')">Open Chat</button>
</div> -->

<router-outlet></router-outlet>

<div id="showFooter" style="visibility: hidden;">
  <app-footer></app-footer>
  <app-chatwee></app-chatwee>
</div>

<!-- <div>
  <app-extra-footer></app-extra-footer>
</div> -->
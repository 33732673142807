import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventComponent } from './event/event.component';
import { BoothComponent } from './booth/booth.component';
import { AuditoriumComponent } from './auditorium/auditorium.component';
import { SupportComponent } from './support/support.component';
import { BoothsComponent } from './booths/booths.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ExternalLinkComponent } from './external-link/external-link.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MobileComponent } from './mobile/mobile.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { AgendaComponent } from './agenda/agenda.component';
import { GameComponent } from './game/game.component';
import { GalleryComponent } from './gallery/gallery.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'event/:eventID', component: EventComponent },
  { path: 'booth/:eventID/:boothID', component: BoothComponent },
  { path: 'booths/:eventID', component: BoothsComponent },
  { path: 'gallery/:eventID', component: GalleryComponent },
  { path: 'game/:eventID', component: GameComponent },
  { path: 'support/:eventID', component: SupportComponent },
  { path: 'agenda/:eventID', component: AgendaComponent },
  { path: 'join/:ide/:pwd', component: RegisterComponent },
  { path: 'externalLink/:eventID/:boothID', component: ExternalLinkComponent },
  { path: 'resetpass/:passToken/:passKey', component: ResetPasswordComponent },
  { path: 'mobile', component: MobileComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'feedback', component: FeedbackFormComponent },
  { path: ':auditoriumID/:eventID', component: AuditoriumComponent },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { MessagingService } from './messaging.service';
import { EventService } from './services/event.service';
import { TimerService } from './services/timer.service';
declare var gtag

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'Cloudvie';
  title = 'Bayer Hemophilia Club';

  constructor(
    private router: Router,
    private eventService: EventService,
    private messageService: MessagingService,
    private toastr: ToastrService,
    private timerService: TimerService
  ) {

    this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      // console.log(this.isMobileDevice());

      // console.log(this.getOrientation());

      // infinity
      // gtag('config', 'G-BRBQ8KMWZ2', { 'page_path': e.urlAfterRedirects });

      // Meeting
      gtag('config', 'G-7RNRY4XRD9', { 'page_path': e.urlAfterRedirects });
      window.scrollTo(0, 0);

      // if (this.getOrientation() == "Portrait") {
      //   // this.router.navigate(['mobile']);
      // }
    });

    this.eventService.showFooter.subscribe((response: boolean) => {
      if (response) {
        document.getElementById("showFooter").style.visibility = "visible";
      } else {
        document.getElementById("showFooter").style.visibility = "hidden";
      }
    });

    this.messageService.currentMessage.subscribe(response => {
      console.log(response);
      if (response) {
        this.toastr.info(response.notification.body, response.notification.title, { closeButton: true, disableTimeOut: true });
      }
    });

    window.onbeforeunload = (e) => {
      this.timerService.sendEndActivity(this.timerService.endTimer());
    }
  }

  isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  getOrientation() {
    var orientation = window.innerWidth > window.innerHeight ? "Landscape" : "Portrait";
    return orientation;
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {
  feedbackForm: FormGroup;
  disabled: boolean = false;
  successMessage: string;
  questions: any;
  loading: boolean = true;
  email: string = '';

  constructor(
    private eventService: EventService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {

    this.email = localStorage.getItem('email') ? localStorage.getItem('email') : '';

    this.feedbackForm = this.fb.group({
      'a1': ['2'],
      'a2': ['2'],
      'a3': ['2'],
      'a4': ['2'],
      'a5': ['2'],
      'a6': ['2'],
      'a7': ['2'],
      'a8': ['2'],
      'a9': ['2'],
      'a10': new FormControl(''),
      'email': new FormControl(this.email),
    });

    this.eventService.getFeedbackQuestions().subscribe((data: any) => {
      // console.log(data);
      if (data.q10) {
        delete data.q10;
      }
      this.questions = data;
      console.log(this.questions);

      this.loading = false;
    }, error => {
      this.loading = false;
      console.log(error);
    })

  }

  submitForm() {
    this.feedbackForm.markAllAsTouched();

    if (this.feedbackForm.status === 'VALID') {

      this.disabled = true;

      let body = {
        'a1': this.feedbackForm.get('a1').value ? this.feedbackForm.get('a1').value : '',
        'a2': this.feedbackForm.get('a2').value ? this.feedbackForm.get('a2').value : '',
        'a3': this.feedbackForm.get('a3').value ? this.feedbackForm.get('a3').value : '',
        'a4': this.feedbackForm.get('a4').value ? this.feedbackForm.get('a4').value : '',
        'a5': this.feedbackForm.get('a5').value ? this.feedbackForm.get('a5').value : '',
        'a6': this.feedbackForm.get('a6').value ? this.feedbackForm.get('a6').value : '',
        'a7': this.feedbackForm.get('a7').value ? this.feedbackForm.get('a7').value : '',
        'a8': this.feedbackForm.get('a8').value ? this.feedbackForm.get('a8').value : '',
        'a9': this.feedbackForm.get('a9').value ? this.feedbackForm.get('a9').value : '',
        'a10': this.feedbackForm.get('a10').value ? this.feedbackForm.get('a10').value : '',
        'email': this.feedbackForm.get('email').value ? this.feedbackForm.get('email').value : '',
      }

      console.log(body);
      // this.disabled = false;
      // return

      this.eventService.sendFeedbackAnswers(body).subscribe((data: string) => {
        this.successMessage = data;
        this.disabled = false;
      }, error => {
        console.log(error);
        this.disabled = false;
      })

    }
  }

}

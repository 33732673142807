<div class="my-container">
  <ng-template #customLoadingTemplate>
  </ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop:true }"
    [template]="customLoadingTemplate"></ngx-loading>
</div>

<app-header [eventID]="eventID"></app-header>

<div [class]="'container-fluid background desktop-only template' + activeTemplate"
  [ngStyle]="{ 'background-image': 'url(' + backgroundImage.src + ')'}">

  <div [routerLink]="['/booth', eventID, 'booth1']" [class.booth1]="numberOfBooths > 2"
    [class.oneBooth]="numberOfBooths == 1" [class.oneOfTwo]="numberOfBooths == 2"></div>

  <div [routerLink]="['/booth', eventID, 'booth2']" *ngIf="numberOfBooths > 1" [class.booth2]="numberOfBooths > 2"
    [class.twoOfTwo]="numberOfBooths == 2"></div>

  <div class="booth3" [routerLink]="['/booth', eventID, 'booth3']" *ngIf="numberOfBooths > 2"></div>

  <div class="gallery" [routerLink]="['/gallery', eventID]" *ngIf="numberOfBooths == 1"></div>

</div>







<!-- Mobile Only -->

<div [class]="'container-fluid mobileBackground mobile-only position-relative mobileTemplate' + activeTemplate"
  [ngStyle]="{ 'background-image': 'url(' + this.mobileBackgroundImage.src + ')'}">


  <div [routerLink]="['/booth', eventID, 'booth1']" [class.booth1]="numberOfBooths > 2"
    [class.oneBooth]="numberOfBooths == 1" [class.oneOfTwo]="numberOfBooths == 2"></div>

  <div [routerLink]="['/booth', eventID, 'booth2']" *ngIf="numberOfBooths > 1" [class.booth2]="numberOfBooths > 2"
    [class.twoOfTwo]="numberOfBooths == 2"></div>

  <div class="booth3" [routerLink]="['/booth', eventID, 'booth3']" *ngIf="numberOfBooths > 2"></div>
</div>

<!-- <app-footer></app-footer> -->
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // SERVER: 'https://cors-anywhere.herokuapp.com/https://api-infinity.cloudvie.live/web/',
  SERVER: 'https://api.cloudvie.live/web/',
  API_KEY: '9Ld0a26eb6cde16sZtAso3cg9XBfmy2N',
  merchantKey: '7Ld0a28bcd27hzyxeDjCgZ-qwzaOWJg3',
  CHATWEE_ID: '5ef0eea6a559034694271284',
  CLIENT_API_KEY: '6e6fdb01e6609de35fd5132a',
  SESSION_COOKIE_KEY: 'chatwee-SID-5ef0eea6a559034694271284',

  // Normal
  firebaseConfig: {
    apiKey: 'AIzaSyBDZ8A7A6Im_Nbg8j1LbjhjwyXdCVR41cQ',
    authDomain: 'cloudvie-1.firebaseapp.com',
    databaseURL: 'https://cloudvie-1.firebaseio.com',
    projectId: 'cloudvie-1',
    storageBucket: 'cloudvie-1.appspot.com',
    messagingSenderId: '639802376464',
    appId: '1:639802376464:web:86ad998807a1a4ebcd6595',
    measurementId: 'G-43LYJ6HJS3',
  },

  // Infinity
  // firebaseConfig: {
  //   apiKey: "AIzaSyBsDZ2H46JcVZQ7Ls_iBwwfAx9A1AM9peg",
  //   authDomain: "cloud-vie-infinity.firebaseapp.com",
  //   projectId: "cloud-vie-infinity",
  //   storageBucket: "cloud-vie-infinity.appspot.com",
  //   messagingSenderId: "827152396511",
  //   appId: "1:827152396511:web:a2ecb72fdc3ab26c47396a",
  //   measurementId: "G-K0L2L7LLNY"
  // }
};

// https://cors-anywhere.herokuapp.com/https://api-infinity.cloudvie.live/web/
// https://cors-anywhere.herokuapp.com/https://api.cloudvie.live/web/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(private afs: AngularFirestore) { }


  // To listen to changes
  // this.afs.collection('collection-name', ref => ref.where('matchId', '==', matchId)).valueChanges();

  // set Values
  // this.afs.doc('matches/' + matchId).set({
  //  matchId,
  //  user1,
  //  user2,
  //  messages: []
  // })

  // Update Value
  // this.afs.doc('matches/' + matchId).update(
  //   { messages: list }
  // )

  getCompetition(competitionName = 'competition 1') {
    return this.afs.collection('competitions', ref => ref.where('name', '==', competitionName)).valueChanges();
  }

  getLeaderboard(competitionName = 'competition 1') {
    return this.afs.collection('leaderboard', ref => ref.where('for', '==', competitionName)).valueChanges();
  }

  submitResults(competitionName = 'competition 1', name, email, score) {
    return this.afs.doc('leaderboard/' + competitionName).update({
      scoreboard: {
        name,
        score,
        email
      }
    })
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { VirtualTimeScheduler } from 'rxjs';
import { EventService } from '../services/event.service';
import { Title } from '@angular/platform-browser';
import { TimerService } from '../services/timer.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, OnDestroy {
  supportChatID: string;
  eventID: string;
  backgroundImage = new Image();
  mobileBackgroundImage = new Image();
  activateBooths: boolean = false;
  activeTemplateID: number;

  time = new Date();
  timeGMT: any;
  countDown: any;
  meetingCountdown: any;
  eventStarted: boolean = false;
  eventEnded: boolean = false;

  secondaryColor: any;

  rooms = [];

  loading: boolean = false;

  mainHall = {
    mobileBoothImg: new Image(),
    mobileAuditoriumImg: new Image(),
    mobileGalleryImg: new Image(),
  };

  numberOfBooths: number;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private router: Router,
    private titleService: Title,
    private timerService: TimerService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.eventID = this.route.snapshot.params['eventID'];
    if (this.eventService.currentEvent) {
      this.titleService.setTitle(this.eventService.currentEvent.events.name + ' Event Reception' + ' - Cloudvie');
      this.supportChatID = this.eventService.currentEvent.events.supportChatID;
      this.backgroundImage.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/support.jpg';
      this.mobileBackgroundImage.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/mobile-support.jpg';
      this.activateBooths = this.eventService.currentEvent.booths ? true : false;
      this.activeTemplateID = this.eventService.currentEvent.events.template;

      this.mainHall.mobileBoothImg.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/mobile-booth-btn.png';
      this.mainHall.mobileAuditoriumImg.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/mobile-auditorium-btn.png';
      this.mainHall.mobileGalleryImg.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/mobile-gallery-btn.png';

      this.numberOfBooths = this.eventService.currentEvent.booths.length;



      this.rooms = this.eventService.currentEvent.rooms.filter(room => +room.type == 1);

      this.secondaryColor = this.eventService.currentEvent.events.secondaryColor;

      setInterval(() => {
        this.time = new Date();
        this.timeGMT = this.adjustForTimezone(new Date());
        // this.toastr.success('', 'Toastr fun!');
      }, 1000);




      this.timerService.timer(new Date((this.eventService.currentEvent.events.startTimeinGMT * 1000))).subscribe(respnseTime => {
        this.countDown = respnseTime;

        if ((this.adjustForTimezone(new Date(this.eventService.currentEvent.events.startTimeinGMT * 1000)).getTime() / 1000 - this.adjustForTimezone(new Date).getTime() / 1000) < 0) {
          this.eventStarted = true;
        };
        if ((this.adjustForTimezone(new Date(this.eventService.currentEvent.events.endTimeinGMT * 1000)).getTime() / 1000 - this.adjustForTimezone(new Date).getTime() / 1000) < 0) {
          this.eventEnded = true;
        };
        if ((this.adjustForTimezone(new Date(this.eventService.currentEvent.events.endTimeinGMT * 1000)).getTime() / 1000 - this.adjustForTimezone(new Date).getTime() / 1000) < 30000) {
          // Message before ending
          // this.eventService.showMessageBeforeEnding();
        };

        // console.log(this.adjustForTimezone(new Date(this.eventService.currentEvent.events.endTimeinGMT * 1000)).getTime());
      });

      // console.log(+this.eventService.currentEvent.events.startTimeinGMT + 4140000);
      this.timerService.timer(new Date(((+this.eventService.currentEvent.events.startTimeinGMT + 4140 + 900) * 1000))).subscribe(respnseTime => {
        // console.log(respnseTime);
        this.meetingCountdown = respnseTime;
      });


      if (this.eventService.currentEvent.support.length) {
        this.timerService.sendActivity(this.eventService.currentEvent.support[0].rooms);
      }




    } else {
      this.router.navigate(['/event', this.eventID]);
    }
  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date
  }

  openChat(externalChatLink) {
    // window.open(externalLink, 'name', 'width=500,height=500');

    this.eventService.openChat.next({
      name: 'Reception',
      url: externalChatLink
    })

  }

  redirectToSession(guid?: string) {
    this.loading = true;

    const name = this.rooms.find(room => room.guid == guid).name;
    console.log(name);
    // this.loading = false;
    // this.router.navigate(['/' + this.eventService.convertSpacesintoDashes(name).toLowerCase(), this.eventID]);
    // return;

    this.eventService.redirectToSession(guid).subscribe(response => {
      // console.log(response);
      const name = this.rooms.find(room => room.guid == guid).name;
      console.log(name);
      this.loading = false;
      this.router.navigate(['/' + this.eventService.convertSpacesintoDashes(name).toLowerCase(), this.eventID]);
    }, error => {
      console.log(error);
      this.loading = false;
      this.toastr.error('', error.error, { closeButton: true, disableTimeOut: true });
    })
  }

  ngOnDestroy() {
    this.timerService.sendEndActivity(this.timerService.endTimer());
  }


}

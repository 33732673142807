import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
const shajs = require('sha.js');

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  disabled: boolean = false;
  passToken: string;
  passKey: string;
  message: string;
  successMessage: string;
  errroMessage: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private titleService: Title
  ) { }

  ngOnInit(): void {

    this.titleService.setTitle('Reset Password' + ' - Cloudvie');

    localStorage.clear();

    this.passToken = this.route.snapshot.params['passToken'];
    this.passKey = this.route.snapshot.params['passKey'];

    this.resetForm = new FormGroup({
      'password': new FormControl(null, Validators.required),
      'confirmPassword': new FormControl(null, Validators.required),
    })
  }

  submitForm() {
    this.resetForm.markAllAsTouched();

    if (this.resetForm.status === 'VALID' && (this.resetForm.get('password').value == this.resetForm.get('confirmPassword').value)) {

      this.disabled = true;

      let timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
      let string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
      let token = shajs('sha256').update(string).digest('hex');

      let body = {
        "pass_token": this.passToken,
        "pass_key": this.passKey,
        "pass_new": this.resetForm.get('password').value,
        "timestamp": timeNowinGMT,
        "token": token
      }

      // console.log(body);

      this.authService.resetPassword(body).subscribe((response: any) => {
        // console.log(response);
        this.successMessage = 'Password Changed Successfully';
        this.disabled = false;
      }, error => {
        console.log(error);
        this.errroMessage = error.error;
        this.disabled = false;
      })

    }
  }


  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date
  }

}

<div class="container-fluid header">
  <div class="logo h-100 d-inline-block" dropdown>
    <a [routerLink]="['/event', eventID]">
      <!-- Meeting -->
      <img
        src="assets/images/Logo-colors-short.png"
        alt="Cloudvie Logo"
        id="logo"
      />

      <!-- Infinity -->
      <!-- <img src="assets/images/bayer-logo.png" alt="Cloudvie Logo" id="logo" style="height: 28px;"> -->
    </a>

    <fa-icon
      [icon]="faArrowLeft"
      class="text-white desktop-only"
      (click)="backClicked()"
    ></fa-icon>

    <h6
      [routerLink]="['/event', eventID]"
      class="desktop-only"
      *ngIf="enableMainHall"
    >
      <fa-icon [icon]="faLaptopHouse" class="desktop-only mr-1 ml-0"></fa-icon>
      {{ allData.rooms[0].name }}
    </h6>

    <h6
      [routerLink]="['/support', eventID]"
      class="desktop-only"
      *ngIf="allData && allData.support?.length"
    >
      <fa-icon [icon]="faInfo" class="mr-1 ml-0"></fa-icon>
      {{ allData.support[0].name }}
    </h6>

    <h6
      [routerLink]="['/auditorium', eventID]"
      class="desktop-only"
      *ngIf="countDown && +countDown.seconds < 0"
    >
      <fa-icon [icon]="faVideo" class="desktop-only mr-1 ml-0"></fa-icon>
      Auditorium
    </h6>

    <h6
      [routerLink]="['/booths', eventID]"
      *ngIf="activateBooths"
      class="desktop-only"
    >
      <fa-icon [icon]="faArchway" class="desktop-only mr-1 ml-0"></fa-icon>
      Booths
    </h6>

    <h6
      [routerLink]="['/gallery', eventID]"
      class="desktop-only"
      *ngIf="allData && allData.gallery?.length"
    >
      <fa-icon [icon]="faImage" class="mr-1 ml-0"></fa-icon>
      {{ allData.gallery[0].name }}
    </h6>

    <h6 [routerLink]="['/agenda', eventID]" class="desktop-only">
      <fa-icon [icon]="faClenderAlt" class="desktop-only mr-1 ml-0"></fa-icon>
      Agenda
    </h6>

    <!-- <h6 [routerLink]="['/plenary-session', eventID]" class="desktop-only" *ngIf="countDown && +countDown.seconds < 0">
      <fa-icon [icon]="faVideo" class=" desktop-only mr-1 ml-0"></fa-icon>
      Plenary Session
    </h6> -->

    <h6
      class="vertical-center m-0"
      dropdownToggle
      aria-controls="dropdown-download"
      style="cursor: pointer"
      *ngIf="downloadLinks.length"
      class="desktop-only"
    >
      <fa-icon [icon]="faDownload" class="desktop-only mr-1 ml-0"></fa-icon>
      Download Materials
    </h6>

    <!-- Only for JIVI -->
    <!-- <h6 class="vertical-center m-0 desktop-only" style="cursor: pointer;" (click)="openBayerModal(BayerAPIModal)">
      <fa-icon [icon]="faPollH" class=" desktop-only mr-1 ml-0"></fa-icon>
      API
    </h6> -->

    <h6
      class="vertical-center m-0"
      style="cursor: pointer"
      (click)="openFullScreen()"
      *ngIf="!fullscreen"
    >
      <fa-icon [icon]="faExpand" class="mr-1 ml-0"></fa-icon>
      <span class="desktop-only">Go Fullscreen</span>
    </h6>

    <h6
      class="vertical-center m-0"
      style="cursor: pointer"
      (click)="closeFullScreen()"
      *ngIf="fullscreen"
    >
      <fa-icon [icon]="faCompress" class="mr-1 ml-0"></fa-icon>
      <span class="desktop-only">Exit Fullscreen</span>
    </h6>

    <ul
      id="dropdown-download"
      *dropdownMenu
      class="dropdown-menu"
      role="menu"
      aria-labelledby="button-animated"
    >
      <li role="menuitem" *ngFor="let downloadLink of downloadLinks">
        <a
          class="dropdown-item"
          [href]="this.downloadLink.url"
          target="_blank"
          class="dropdown-item"
          download
          >{{ downloadLink.name }}</a
        >
      </li>
    </ul>

    <cuppa-slidemenu
      [menulist]="menuItemsArray"
      (open)="onMenuOpen()"
      (close)="onMenuClose()"
      (onItemSelect)="onItemSelect($event)"
      class="mobile-only"
      [config]="menuConfig"
    ></cuppa-slidemenu>
  </div>

  <div class="user-pic float-right helper-div h-100">
    <span class="helper"></span>
    <fa-icon
      [icon]="faUser"
      class="text-white vertical-center"
      *ngIf="!profilePic"
    ></fa-icon>
    <img
      [src]="profilePic"
      alt="profile picture "
      class="rounded-circle desktop-only"
      style="width: 1vw"
      *ngIf="profilePic"
    />
    <img
      [src]="profilePic"
      alt="profile picture "
      class="rounded-circle mobile-only"
      style="width: 5vw"
      *ngIf="profilePic"
    />
  </div>

  <div class="float-right text-white helper-div h-100 mr-3" dropdown>
    <span class="helper"></span>
    <h6
      class="vertical-center m-0"
      dropdownToggle
      aria-controls="dropdown-animated"
      style="cursor: pointer"
    >
      {{ name }}
    </h6>

    <ul
      id="dropdown-animated"
      *dropdownMenu
      class="dropdown-menu"
      role="menu"
      aria-labelledby="button-animated"
    >
      <li role="menuitem">
        <a class="dropdown-item" (click)="signout()">Logout</a>
      </li>
    </ul>
  </div>
</div>

<ng-template #BayerAPIModal>
  <div class="modal-header border-0">
    <!-- <h2 class="modal-title pull-left desktop-only">Welcome to {{title}}</h2> -->
    <button
      type="button"
      class="close pull-right text-white"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <!-- <span aria-hidden="true">&times;</span> -->
      <fa-icon [icon]="faTimesCircle" class="text-white"></fa-icon>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-white">API:</p>

    <p class="text-white">
      <b>Jivi ® - Composition</b>: Powder with 500/1000/2000 IE of Damoctocog
      alfa Pegol (pegylated B-domain-deleted recombinant human coagulation
      factor VIII) per injection vial. <b>Indications</b>: Therapy and
      prophylaxis of bleeding in previously treated patients (PTPs) ≥12 years
      with hemophilia A (congenital factor VIII deficiency). Not indicated in
      case of von Willebrand disease. <br />
      <b>Dosage & Administration</b>: The posology and duration of factor VIII
      substitution are adjusted to the individual needs of the patient
      (depending on patient weight, degree of factor VIII deficiency, location
      and extent of bleeding and desired factor VIII plasma level).
      Recommendation for initial prophylaxis 30-40 IU/kg twice weekly Based on
      the patient‘s frequency of bleeding, 45-60 IU/kg can be applied every
      5 days. It can be further adjusted to less frequent doses to suit
      individual requirements, e.g. 60 IU/kg every 7 days. <br />
      <b>Preparation</b>: Mixing with other medicinal products & injection
      solutions is not permitted. <b>Contra-indications</b>: Hypersensitivity to
      the active substance or to one of the excipients. Known allergy to mouse
      or hamster proteins & It is not indicated for use in children under
      12 years of age due to an increased risk of hypersensitivity reactions. -
      <b>Warnings</b>: Hypersensitivity reactions (including anaphylaxis).
      Formation of factor VIII inhibitors. Immune response to polyethylene
      glycol (PEG): in the first 4 days of exposure with symptoms of acute
      hypersensitivity and/or loss of clinical efficacy (temporary, associated
      with anti-PEG antibodies, absence of factor VIII inhibitors). Low factor
      VIII level following the injection without detectable factor VIII
      inhibitors may indicate anti-PEG antibodies; Jivi ® must no longer be
      taken. Catheter-related infections. Risk of contracting cardiovascular
      diseases. Pregnancy and lactation. - <b>Interactions</b>: no interactions
      with other medicinal products have been reported.
      <b>Adverse effects: Very common: </b>Headache, <b>Common</b>:
      Hypersensitivity reactions, insomnia, vertigo, abdominal pain, nausea,
      vomiting, erythema, rash, localized reactions at the injection site,
      fever, cough -
      <b
        >Reporting of side effects: If you get any side effects, talk to your
        doctor or pharmacist. This includes any possible side effects not listed
        in this leaflet. By reporting side affects you can help provide more
        information on the safety of this medicine.</b
      >
    </p>

    <p class="text-white">
      - Please refer to the Product Package Insert Leaflet (PIL) for full
      details of usage and dosage.
    </p>

    <p class="text-white">
      <b>Manufactured by:</b> Bayer HealthCare LLC, Berkeley, USA <br />
      <b>Final release by:</b> Bayer AG - 51368 Leverkusen, Germany. <br />
      <b>ME PIL version: Nov. 2018</b>
    </p>
  </div>
</ng-template>

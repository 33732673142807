<div class="container-contact100" style="background-image: url('assets/images/login-background.jpg');">
  <div class="wrap-contact100">
    <div class="footer" *ngIf="!message">
      <img src="assets/images/registeration-logo.png" class="w-50 pl-2 pr-2">
      <!-- <img src="assets/images/registeration-logo.png" class="w-25 pl-2 pr-2"> -->
    </div>
    <!-- <div class="footer" style="padding-top: 20px;">
      <img src="https://gen.sendtric.com/countdown/epot6k1xgc" style="display: block;" />
      </div>  -->
    <div class="col-12 mt-4">
      <button class="w-75 mx-auto contact100-form-btn" (click)="goToEvent(eventID)"
        *ngIf="!successMessage && !forget && showAlreadyLogged">Continue as &nbsp; <b>{{ fullName }}</b></button>
    </div>

    <form class="contact100-form " [formGroup]="loginForm" (ngSubmit)="submitForm()" *ngIf="!successMessage && !forget">
      <span class="contact100-form-title">
        Welcome to our virtual interactive event
        <p class="mt-3">Please log in with the credentials you created when you registered.</p>
      </span>

      <label class="label-input100" for="email">Enter your email *</label>
      <div class="wrap-input100">
        <input id="email" class="input100" type="text" name="email" placeholder="Eg. example@email.com"
          [formControlName]="'email'">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!loginForm.get('email').valid && loginForm.get('email').touched">Invalid Email</span>
      </div>

      <!-- <label class="label-input100" for="password">Enter your password *</label>
      <div class="wrap-input100">
        <input id="password" class="input100" type="password" name="password" [formControlName]="'password'"
          autocomplete="off">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">Required</span>
      </div>
      <a class="text-right pt-0 mt-1 w-100 font-weight-bold forget-password" style="cursor: pointer;"
        (click)="forget = true">Forget
        Password?</a> -->

      <p class="mt-3 text-danger w-100" *ngIf="message">{{ message }}</p>
      <!-- <p class="text-danger w-100" *ngIf="message">Please check your email and password again.</p> -->

      <div class="container-contact100-form-btn">
        <button type="submit" class="contact100-form-btn" [disabled]="disabled">Login</button>
      </div>

      <div class="container-contact100-form-btn" *ngIf="registerIde">
        <button type="button" class="contact100-form-btn" style="background-color: #22b573;"
          [routerLink]="['/join', registerIde, registerPwd]">Not Registered? Register
          Now</button>
      </div>

    </form>


    <form class="contact100-form " [formGroup]="forgetPasswordForm" (ngSubmit)="submitForgetForm()" *ngIf="forget">
      <span class="contact100-form-title">
        Forget Password
      </span>

      <label class="label-input100" for="email">Enter your email *</label>
      <div class="wrap-input100">
        <input id="email" class="input100" type="text" name="email" placeholder="Eg. example@email.com"
          [formControlName]="'email'">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!forgetPasswordForm.get('email').valid && forgetPasswordForm.get('email').touched">Invalid Email</span>
      </div>

      <p class="mt-3 text-primary w-100" *ngIf="forgetMessage">{{ forgetMessage }}</p>

      <div class="container-contact100-form-btn">
        <button type="submit" class="contact100-form-btn d-block" [disabled]="disabled">Reset Password</button>
      </div>

      <div class="w-100 text-center mt-3">
        <a (click)="forget = false" href="">Back to Login</a>
      </div>


    </form>

    <p *ngIf="successMessage" class="w-100 m-5">{{ successMessage }}</p>


  </div>
</div>

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TimerService } from './timer.service';
const shajs = require('sha.js');

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class EventService {
  headers = new HttpHeaders({
    'api-key': '9Ld0a26eb6cde16sZtAso3cg9XBfmy2N',
    'Content-Type': 'application/json'
  });

  openChat = new Subject();
  closePublicChat = new Subject();
  openPublicChat = new Subject();
  showFooter = new Subject();
  PublicChatVisible: boolean = false;
  currentEvent: any;
  // displayBooths: boolean = false;
  displayBooths = new Subject();
  currentActivityId: any;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private timerService: TimerService
  ) { }

  getEvent() {
    return this.http.get(environment.SERVER + 'events/', { headers: this.headers });
  }

  getTimeinGMT() {
    return this.http.get(environment.SERVER + 'events/timestamp', { headers: this.headers });
  }

  getFeedbackQuestions() {
    return this.http.get(environment.SERVER + 'questions', { headers: this.headers });
  }

  getSpeakers() {
    return this.http.get(environment.SERVER + 'speakers', { headers: this.headers });
  }

  sendFeedbackAnswers(body) {
    return this.http.post(environment.SERVER + 'feedback', body, { headers: this.headers });
  }

  redirectToSession(guid) {

    const timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
    const string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
    const token = shajs('sha256').update(string).digest('hex');

    const body = {
      "session_idu": localStorage.getItem('session'),
      "timestamp": timeNowinGMT,
      "token": token
      // "room": guid
    }

    return this.http.post(environment.SERVER + 'users/priority', body, { headers: this.headers });
  }

  convertSpacesintoDashes(string) {
    // console.log(string.replace(/\s+/g, '-'));
    return string.replace(/\s+/g, '-');
  }

  convertDashesintoSpaces(string) {
    // console.log(string.replace(/-/g, ' '));
    return string.replace(/-/g, ' ');
  }

  isSafari() {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;
  }

  showMessageBeforeEnding() {
    // this.toastr.info('', 'The Summit is about to be finished in 5 minutes or less, Thank you for attending the summit.', { closeButton: true, disableTimeOut: true });
  }

  countTill5Minutes(startTimeinGMT, endTimeinGMT) {

    this.timerService.timer(new Date((startTimeinGMT * 1000))).subscribe(respnseTime => {
      if ((this.adjustForTimezone(new Date(endTimeinGMT * 1000)).getTime() / 1000 - this.adjustForTimezone(new Date).getTime() / 1000) == 300000) {
        // Message before ending
        this.showMessageBeforeEnding();
      };

      // console.log((this.adjustForTimezone(new Date(endTimeinGMT * 1000)).getTime() / 1000 - this.adjustForTimezone(new Date).getTime() / 1000));
    });
  }

  getEventData(ide, pwd) {

    const timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
    const string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
    const token = shajs('sha256').update(string).digest('hex');

    const body = {
      "ide": ide,
      "pwd": pwd,
      "timestamp": timeNowinGMT,
      "token": token
    }

    return this.http.post(environment.SERVER + 'events/settings', body, { headers: this.headers })
  }

  isFullscreen() {
    if ((window.fullScreen) ||
      (window.innerWidth == screen.width && window.innerHeight == screen.height)) {
        return true;
    } else {
      return false;
    }
  }


  // sendActivity(roomID) {

  //   this.timerService.startTimer();

  //   const timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
  //   const string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
  //   const token = shajs('sha256').update(string).digest('hex');

  //   const body = {
  //     "session_idu": localStorage.getItem('session'),
  //     "timestamp": timeNowinGMT,
  //     "token": token,
  //     "rooms": roomID,
  //     "partners": "17",
  //     "speakers": "1"
  //   }

  //   console.log(body)

  //   this.http.post(environment.SERVER + 'users/activities', body, { headers: this.headers }).subscribe((response: any) => {
  //     console.log(response);
  //     console.log("Current ID is " + response.activities_id);
  //     this.currentActivityId = response.activities_id;
  //   }, error => {
  //     console.log(error);
  //   });
  // }

  // sendEndActivity(duration: any) {

  //   this.timerService.timeinSeconds = 0;

  //   const timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
  //   const string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
  //   const token = shajs('sha256').update(string).digest('hex');

  //   const body = {
  //     "session_idu": localStorage.getItem('session'),
  //     "timestamp": timeNowinGMT,
  //     "token": token,
  //     "id": this.currentActivityId,
  //     "duration": duration,
  //     "partners": "17",
  //     "speakers": "1"
  //   }

  //   console.log(body);

  //   this.http.post(environment.SERVER + 'users/duration', body, { headers: this.headers }).subscribe((response: any) => {
  //     console.log(response);
  //   }, error => {
  //     console.log(error);
  //   });

  // }

  are_cookies_enabled() {
    var cookieEnabled = (navigator.cookieEnabled) ? true : false;

    if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
    }
    return cookieEnabled;
  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date
  }

  // eventStructure() {
  //   // Event Body So Far
  //   let event = {

  //     name: "",
  //     guid: "9c2d1a93-46bc-d662-2451-5859e687dab7",

  //     mainHall: {
  //       simplifiedAgenda: "",
  //       backgroundImage: "",
  //       centerVideo: "",
  //       closeLeftVideo: "",
  //       closeRightVideo: "",
  //       farLeftVideo: "",
  //       farRightVideo: "",
  //       introVideo: "",
  //       primaryColor: "",
  //       secondaryColor: "",
  //       startTimeinGMT: "",
  //       endTimeinGMT: "",
  //       topMiddleVideo: "",
  //       mainHallBackgroundImage: "",
  //       detailedAgenda: [
  //         { from: '6:30 PM', to: '07:30 PM', speaker: 'John Due', topic: 'Pharma Intro' },
  //         { from: '7:30 PM', to: '09:00 PM', speaker: 'Mark Schlberg', topic: 'Pharma Chapter I' },
  //         { from: '9 PM', to: '11:00 PM', speaker: 'Felix', topic: 'Pharma Chapter II' },
  //       ]
  //     },

  //     support: {
  //       TawkDirectChatUrl: ""
  //     },

  //     booths: {
  //       backgroundImage: "",
  //       booths1: {
  //         id: 1,
  //         backgroundImage: "",
  //         type: 'game',
  //         chatUrl: "https://tawk.to/chat/5edfae4b4a7c6258179a4179/1eb1juq2h"
  //       },
  //       booths2: {
  //         id: 2,
  //         backgroundImage: "",
  //         type: 'video',
  //         chatUrl: "https://tawk.to/chat/5edfae4b4a7c6258179a4179/1eb1k791d"
  //       },
  //       booths3: {
  //         id: 3,
  //         backgroundImage: "",
  //         type: 'game',
  //         chatUrl: "https://tawk.to/chat/5edfae4b4a7c6258179a4179/1eb1ka0ds"
  //       }
  //     },

  //     auditorium: {
  //       backgroundImage: "",
  //       liveVideoUrl: "https://vimeo.com/event/110715/embed",
  //       chatUrl: "https://vimeo.com/event/110715/chat/",
  //     }

  //   }
  // }

}

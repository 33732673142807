<div class="my-container">
    <ng-template #customLoadingTemplate>
    </ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop:true }"
        [template]="customLoadingTemplate"></ngx-loading>
</div>

<app-header [eventID]="eventID"></app-header>



<div [class]="'container-fluid background desktop-only template' + activeTemplate"
    [ngStyle]="{ 'background-image': 'url(' + backgroundImage.src + ')'}">

    <div class="desktopFrame">
        <swiper [config]="desktopConfig" #desktopGallerySwiper>
            <div class="swiper-wrapper">
                <div *ngFor="let frame of arrayOfPics; let index = index" class="text-center swiper-slide"
                    style="width: 33%; display: inline-block;">
                    <img [src]="frame" alt="Gallery Image" class="w-75 frame" (click)="openViewer(galleryModal, index)">
                </div>
            </div>
        </swiper>
    </div>

    <div class="dNext" (click)="desktopGallerySwiper.swiper.slideNext();"></div>

    <div class="dPrev" (click)="desktopGallerySwiper.swiper.slidePrev();"></div>

</div>


<div [class]="'container-fluid mobileBackground mobile-only position-relative mobileTemplate' + activeTemplate"
    [ngStyle]="{ 'background-image': 'url(' + this.mobileBackgroundImage.src + ')'}">

    <div class="mobileFrame">
        <swiper [config]="config" #gallerySwiper>
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let frame of arrayOfPics; let index = index">
                    <img [src]="frame" alt="Gallery Image" class="w-100" (click)="openViewer(galleryModal, index)">
                </div>
            </div>
        </swiper>
    </div>

    <div class="next" (click)="gallerySwiper.swiper.slideNext();"></div>

    <div class="prev" (click)="gallerySwiper.swiper.slidePrev();"></div>


</div>



<ng-template #galleryModal>
    <div class="modal-header border-0">
        <!-- <h4 class="modal-title pull-left">Modal Header</h4> -->
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img [src]="activeFrame.src" alt="Big Image" class="w-100">
    </div>
</ng-template>
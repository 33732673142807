import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatweeComponent } from './components/chatwee.component';



@NgModule({
    declarations: [ChatweeComponent],
    exports: [
        ChatweeComponent
    ],
    imports: [
        CommonModule
    ]
})
export class LobbyChatModule { }

import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';


@Injectable()

export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging) {

    this.angularFireMessaging.messages.subscribe(
      (msgings: any) => {
        msgings.onMessage = msgings.onMessage.bind(msgings);
        msgings.onTokenRefresh = msgings.onTokenRefresh.bind(msgings);
      })
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.receiveMessage();
      });
  }

  receiveMessage() {

    // console.log('Foreground Notification');

    this.angularFireMessaging.messages.subscribe(
      (msg: any) => {
        // console.log("show message!", msg);
        this.currentMessage.next(msg);
      })
  }
}

<div class="my-container">
  <ng-template #customLoadingTemplate>
  </ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop:true }"
    [template]="customLoadingTemplate"></ngx-loading>
</div>

<app-header [eventID]="eventID"></app-header>

<div [class]="'container-fluid background desktop-only template' + this.templateNumber "
  [ngStyle]="{ 'background-image': 'url(' + backgroundImage.src + ')'}" *ngIf="currentBooth">
  <!-- <a [routerLink]="['/game']" target="_blank" *ngIf="currentBooth.type == 'game'"
    [class.externalLink1]="currentBooth.id == 1" [class.externalLink2]="currentBooth.id == 2"
    [class.externalLink3]="currentBooth.id == 3"></a> -->

  <!-- [routerLink]="['/externalLink', eventID, boothID]" -->

  <a (click)="openModal(externalLinkModal, currentBooth.ExternalLinks)" *ngIf="currentBooth.name"
    [class.externalLink1]="currentBooth.name.toLowerCase() == 'booth1'"
    [class.externalLink2]="currentBooth.name.toLowerCase() == 'booth2'"></a>

  <a (click)="openModal(externalLinkModal, currentBooth.stream)" *ngIf="currentBooth.name"
    [class.externalStream1]="currentBooth.name.toLowerCase() == 'booth1'"
    [class.externalStream2]="currentBooth.name.toLowerCase() == 'booth2'"></a>

  <div [class.chat1]="currentBooth.name.toLowerCase() == 'booth1'" *ngIf="currentBooth.name"
    [class.chat2]="currentBooth.name.toLowerCase() == 'booth2'">
    <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a>
    <!-- <a (click)="openChat(currentBooth.supportChatID)" class="d-block h-100"></a> -->
  </div>

  <div [class.download1-1]="currentBooth.name.toLowerCase() == 'booth1'" *ngIf="currentBooth.materials.length >= 1"
    [class.download2-1]="currentBooth.name.toLowerCase() == 'booth2'">
    <!-- <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a> -->
    <a [href]="this.currentBooth.downloadLinks[0]" target="_blank" class="d-block h-100" download></a>
  </div>

  <div [class.download1-2]="currentBooth.name.toLowerCase() == 'booth1'" *ngIf="currentBooth.materials.length >= 2"
    [class.download2-2]="currentBooth.name.toLowerCase() == 'booth2'">
    <!-- <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a> -->
    <a [href]="this.currentBooth.downloadLinks[1]" target="_blank" class="d-block h-100" download></a>
  </div>

  <div [class.download1-3]="currentBooth.name.toLowerCase() == 'booth1'" *ngIf="currentBooth.materials.length == 3"
    [class.download2-3]="currentBooth.name.toLowerCase() == 'booth2'">
    <!-- <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a> -->
    <a [href]="this.currentBooth.downloadLinks[2]" target="_blank" class="d-block h-100" download></a>
  </div>

  <div class="bounce" [class.support1]="currentBooth.name.toLowerCase() == 'booth1'"
    [class.support2]="currentBooth.name.toLowerCase() == 'booth2'">
    <span class="tooltiptext  font-weight-bold" style="width: 175px;">Need Help?</span>
  </div>

</div>


<ng-template #externalLinkModal>
  <div class="modal-header border-0">
    <!-- <h4 class="modal-title pull-left">Modal Header</h4> -->
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe [src]="activeLink + queryParams + '&loop=1&autoplay=1' | vimeoUrl" width="100%" frameborder="0" style="height: 82vh;" allow="microphone"></iframe>
  </div>
</ng-template>

<!-- <app-footer></app-footer> -->












<!-- Mobile Version -->
<div [class]="'container-fluid mobile-background mobile-only  position-relative  mobileTemplate' + this.templateNumber"
  [ngStyle]="{ 'background-image': 'url(' + mobileBackgroundImage.src + ')'}" *ngIf="currentBooth">


  <a (click)="openModal(externalLinkModal, currentBooth.ExternalLinks)" *ngIf="currentBooth.name"
    [class.externalLink1]="currentBooth.name.toLowerCase() == 'booth1'"
    [class.externalLink2]="currentBooth.name.toLowerCase() == 'booth2'"></a>

  <a (click)="openModal(externalLinkModal, currentBooth.stream)" *ngIf="currentBooth.name"
    [class.externalStream1]="currentBooth.name.toLowerCase() == 'booth1'"
    [class.externalStream2]="currentBooth.name.toLowerCase() == 'booth2'"></a>

  <div [class.chat1]="currentBooth.name.toLowerCase() == 'booth1'" *ngIf="currentBooth.name"
    [class.chat2]="currentBooth.name.toLowerCase() == 'booth2'">
    <!-- <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a> -->
    <a (click)="openChat(currentBooth.supportChatID)" class="d-block h-100"></a>
  </div>

  <div [class.download1-1]="currentBooth.name.toLowerCase() == 'booth1'" *ngIf="currentBooth.materials.length >= 1"
    [class.download2-1]="currentBooth.name.toLowerCase() == 'booth2'">
    <!-- <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a> -->
    <a [href]="this.currentBooth.downloadLinks[0]" target="_blank" class="d-block h-100" download></a>
  </div>

  <div [class.download1-2]="currentBooth.name.toLowerCase() == 'booth1'" *ngIf="currentBooth.materials.length >= 2"
    [class.download2-2]="currentBooth.name.toLowerCase() == 'booth2'">
    <!-- <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a> -->
    <a [href]="this.currentBooth.downloadLinks[1]" target="_blank" class="d-block h-100" download></a>
  </div>

  <div [class.download1-3]="currentBooth.name.toLowerCase() == 'booth1'" *ngIf="currentBooth.materials.length == 3"
    [class.download2-3]="currentBooth.name.toLowerCase() == 'booth2'">
    <!-- <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a> -->
    <a [href]="this.currentBooth.downloadLinks[2]" target="_blank" class="d-block h-100" download></a>
  </div>

  <div class="bounce" [class.support1]="currentBooth.name.toLowerCase() == 'booth1'"
    [class.support2]="currentBooth.name.toLowerCase() == 'booth2'">
    <span class="tooltiptext  font-weight-bold" style="width: 75px;">Need Help?</span>
  </div>

</div>
<div class="my-container" style="background-color: #008ab4;">
  <ng-template #customLoadingTemplate>
  </ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop:true }"
    [template]="customLoadingTemplate"></ngx-loading>
</div>

<form  class="jotform-form" [formGroup]="feedbackForm" (ngSubmit)="submitForm()"
  style="background-color: #008ab4; padding-bottom: 10px; min-height: 100vh;">
  <div  class="head"  style="background-color: #008ab4;">

  </div>
  <div role="main" class="form-all" *ngIf="!successMessage">

    <div class="head">
      <img src="assets/images/feedback-logo.png">
    </div>
    <ul class="form-section page-section" *ngIf="questions">
      <li id="cid_1" class="form-input-wide" data-type="control_head">
        <div class="form-header-group  header-large">
          <div class="header-text httal htvam">
            <h1 id="header_1" class="form-header" data-component="header">
              First Saudi Women Cancer Summit Feedback form
            </h1>
            <div id="subHeader_1" class="form-subHeader">
              You can write anything in here about the summit.
            </div>
          </div>
        </div>
      </li>

      <!-- Looping for the array of Questions -->
      <li class="form-line jf-required" data-type="control_radio"
        *ngFor="let question of questions | keyvalue, let qIndex = index" [id]="question.key">
        <label class="form-label form-label-top form-label-auto" [id]="question.key" [for]="question.key">
          {{ question.value.label }}
        </label>
        <div id="cid_0" class="form-input-wide jf-required" data-layout="full">
          <div class="form-single-column" role="group" aria-labelledby="label_40" data-component="radio">
            <span class="form-radio-item" style="clear:left"
              *ngFor="let option of question.value.options | keyvalue, let index = index">
              <div *ngIf="option.value[index + 1]">
                <span class="dragger-item">
                </span>
                <input type="radio" class="form-radio validate[required]"
                  [id]="question.key + option.key" value="{{(index + 1)}}" formControlName="{{'a' + (qIndex + 1)}}"
                  [name]="'a' + (qIndex + 1)" [checked]="index == 1"/>
                <label [id]="question.key + option.key + index" [for]="question.key + option.key">
                  {{option.value[index + 1]}}
                </label>
              </div>
            </span>
          </div>
        </div>
      </li>

      <!-- Final Comment -->
      <li class="form-line" data-type="control_textarea" id="id_38">
        <label class="form-label form-label-top form-label-auto" id="label_38" for="input_38"> Any final comments?
        </label>
        <div id="cid_38" class="form-input-wide" data-layout="full">
          <textarea id="input_38" class="form-textarea" style="width:40px;height:46px" data-component="textarea"
            aria-labelledby="label_38" [formControlName]="'a10'" name="a10"></textarea>
        </div>
      </li>

      <!-- Checking for his email -->
      <li class="form-line" data-type="control_textarea" id="id_39">
        <label class="form-label form-label-top form-label-auto" id="label_39" for="input_39"> Enter your Email
        </label>
        <div id="cid_39" class="form-input-wide" data-layout="full">
          <input id="input_39" class="form-textarea" style="width:40px;height:46px" aria-labelledby="label_39"
            name="email" [formControlName]="'email'">
        </div>
      </li>

    </ul>
    <div class="container-contact100-form-btn">
      <input type="submit" class="contact100-form-btn" [disabled]="disabled">
    </div>
    <div class="footer">
      <img src="assets/images/registeration-logo.png">
    </div>
  </div>

  <div role="main" class="form-all" *ngIf="successMessage">
    <p class="p-5 text-center m-2">{{ successMessage }}</p>
  </div>
</form>

import { Component, OnInit, TemplateRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { EventService } from '../services/event.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { faComment, faUsers, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  @ViewChild('chatModal', { static: false }) chatModal: TemplateRef<any>;
  @ViewChild('publicChatModal', { static: false }) publicChatModal: TemplateRef<any>;
  activeChat: any;
  chatArray: any = [];
  subjectSubscription: Subscription;
  faComment = faComment;
  faUsers = faUsers;
  faCommentAlt = faCommentAlt;
  primaryColor: string;
  secondaryColor: string;
  faTimes = faTimes;
  cookiesEnabled: boolean;

  activateChatWee: boolean;

  // isSafari: boolean = this.eventService.isSafari();
  isSafari: boolean = true;
  faExternalLinkAlt = faExternalLinkAlt;

  constructor(
    private eventService: EventService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {

    this.cookiesEnabled = this.eventService.are_cookies_enabled();
    // console.log(this.cookiesEnabled);

    // document.getElementById("closeDiv").style.visibility = "hidden";

    if (this.eventService.currentEvent) {
      this.getList(this.eventService.currentEvent);
      this.primaryColor = this.eventService.currentEvent.events.primaryColor;
      this.secondaryColor = this.eventService.currentEvent.events.secondaryColor;
      this.activateChatWee = this.eventService.currentEvent.events.EnableChatWee;

    } else {
      this.eventService.getEvent().subscribe((response: any) => {
        this.getList(response);
        this.primaryColor = response.events.primaryColor;
        this.secondaryColor = response.events.secondaryColor;
        this.activateChatWee = response.events.EnableChatWee;
      })
    }

    this.subjectSubscription = this.eventService.openChat.subscribe((response: any) => {
      // console.log(response);
      this.openModal(this.chatModal, response.url);
    });

    // this.modalService.onHide.subscribe(event => {
    //   // this.eventService.closePublicChat.next(true);
    // })

  }

  getList(response: any) {

    this.chatArray = [];

    this.chatArray.push(
      {
        name: 'Support',
        url: response.events.supportChatID
      }
    );

    this.eventService.displayBooths.pipe(take(1)).subscribe(data => {
      response.booths.forEach(booth => {
        this.chatArray.push({
          name: booth.subject + ' Booth',
          url: booth.supportChatID
        }
        )
      });
    })

    console.log(this.chatArray);

  }

  openModal(chatModal: TemplateRef<any>, link) {
    this.activeChat = link;
    this.modalRef = this.modalService.show(chatModal);

    // setTimeout(() => {

    //   let tawkChatDiv = document.getElementById('tawkChatDiv');

    //   console.log('is this Browser Safari? ' + this.eventService.isSafari());

    //   tawkChatDiv.innerHTML = '<iframe allowfullscreen="true" src=' + link + ' frameborder="0" width="100%" height="625"></iframe>';

    //   tawkChatDiv.querySelector("iframe").addEventListener("load", function () {
    //     console.warn("Video loaded");
    //     tawkChatDiv.querySelector("iframe").focus();
    //   });
    // }, 300);

    // this.eventService.openPublicChat.next(true);
    // console.log(link);
  }

  openPublicChatModal(publicChatModal) {
    // this.modalRef = this.modalService.show(publicChatModal, { class: 'bg-modal' });
    document.getElementById("chatwee-widget").style.visibility = "visible";
    document.getElementById("closeDiv").style.visibility = "visible";
    setTimeout(() => {
      this.eventService.PublicChatVisible = true;
    }, 500)
    // console.log(link);
  }

  closePublicChat() {
    document.getElementById("chatwee-widget").style.visibility = "hidden";
    document.getElementById("closeDiv").style.visibility = "hidden";
  }

  ngOnDestroy() {
    if (this.eventService.currentEvent) {
      this.subjectSubscription.unsubscribe();
    }
  }

}

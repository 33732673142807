import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventService } from '../services/event.service';
import { Title } from '@angular/platform-browser';
import { TimerService } from '../services/timer.service';

@Component({
  selector: 'app-booths',
  templateUrl: './booths.component.html',
  styleUrls: ['./booths.component.scss']
})
export class BoothsComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  eventID: string;
  currentBooths: any;
  backgroundImage = new Image();
  mobileBackgroundImage = new Image();
  numberOfBooths: number;
  activeTemplate: number;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private router: Router,
    private titleService: Title,
    private timerService: TimerService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.eventID = params.eventID;

      if (this.eventService.currentEvent) {

        this.numberOfBooths = this.eventService.currentEvent.booths.length;

        this.titleService.setTitle(this.eventService.currentEvent.events.name + ' Booths' + ' - Cloudvie');

        console.log("Current Event")
        console.log(this.eventService.currentEvent);

        this.currentBooths = this.eventService.currentEvent.rooms.find(room => room.name == "Booths");
        // this.currentBooths.backgroundImage = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/booths.jpg';
        this.backgroundImage.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/booths.jpg';
        this.mobileBackgroundImage.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/mobile-booths.jpg';
        this.activeTemplate = this.eventService.currentEvent.events.template;
        // console.log(this.currentBooths);
        this.eventService.displayBooths.next(true);
        this.timerService.sendActivity(0);

        // if (this.currentBooths && this.currentBooths.id) {
        //   this.timerService.sendActivity(this.currentBooths.id);
        // }

        // console.log(this.numberOfBooths);
        if (this.numberOfBooths == 1 && this.eventService.currentEvent.events.template != 3) {
          this.router.navigate(['/booth', this.eventID, 'booth1']);
        }

        // console.log(this.currentBooths);
      } else {
        this.router.navigate(['/event', this.eventID]);
      }


    });

    setTimeout(() => {
      this.loading = false;
    }, 1500)
  }

  getEvent() {

  }

  ngOnDestroy() {
    this.timerService.sendEndActivity(this.timerService.endTimer());
  }

}

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SwiperOptions } from 'swiper';
import { EventService } from '../services/event.service';
import { TimerService } from '../services/timer.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  eventID: string;
  activeTemplate: number;
  backgroundImage = new Image();
  mobileBackgroundImage = new Image();
  activeFrame = new Image();
  arrayOfPics: any = [];

  noOfPics: number = 6;

  @ViewChild('galleryModal', { static: false }) galleryModal: TemplateRef<any>;
  modalRef: BsModalRef;

  config: SwiperOptions = {
    autoHeight: true,
    allowTouchMove: true,
    slidesPerView: 1,
    simulateTouch: false,
  };

  desktopConfig: SwiperOptions = {
    allowTouchMove: true,
    slidesPerView: 'auto',
    simulateTouch: false,
    direction: 'horizontal'
  }

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private router: Router,
    private modalService: BsModalService,
    private titleService: Title,
    private timerService: TimerService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {

      this.eventID = params.eventID;

      if (this.eventService.currentEvent) {

        this.backgroundImage.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/gallery.jpg';
        this.mobileBackgroundImage.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/gallery_mobile.jpg';
        this.activeTemplate = this.eventService.currentEvent.events.template;

        for (let i = 1; i <= this.noOfPics; i++) {
          const picLink = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/gallery/frame' + i + '.png';
          this.arrayOfPics.push(picLink);
        }

        console.log(this.arrayOfPics)

        setTimeout(() => {
          this.loading = false;
        }, 500);

        this.timerService.sendActivity(this.eventService.currentEvent.gallery[0].rooms);

      } else {
        this.router.navigate(['/event', this.eventID]);
      }

    })

  }

  openViewer(modal: TemplateRef<any>, frameIndex: any) {

    console.log("assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/gallery/frame' + (frameIndex + 1) + '_big.png');
    console.log(frameIndex);
    this.activeFrame.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/gallery/frame' + (frameIndex + 1) + '_big.png';
    this.modalRef = this.modalService.show(modal, { class: 'bg-modal' });
  }

  ngOnDestroy() {
    this.timerService.sendEndActivity(this.timerService.endTimer());
  }

}

<div class="my-container">
  <ng-template #customLoadingTemplate>
  </ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop:true }"
    [template]="customLoadingTemplate"></ngx-loading>
</div>



<div class="container-contact100" [ngStyle]="{ 'background-color': eventData.PrimaryColor }" *ngIf="eventData">
  <div class="wrap-contact100">


    <div class="head" style="padding-top: 0px;">
      <img style="width: 100%; align-self: flex-start;" [src]="'assets/images/' + eventData.HeadImage">
    </div>
    <!-- <div class="footer" style="padding-top: 0px;">
    <img src="https://gen.sendtric.com/countdown/epot6k1xgc" style="display: block;" />
    </div>  -->

    <form class="contact100-form " [formGroup]="registerForm" (ngSubmit)="submitForm()" *ngIf="!message">




      <span class="contact100-form-title" [ngStyle]="{ 'color': eventData.SecondaryColor}">
        Register For <br><span style="font-size: 0.9em; padding-bottom:0px;" class="contact100-form-title"
          [ngStyle]="{ 'color': eventData.PrimaryColor}">{{ eventData.Name }}</span>

      </span>

      <label class="label-input100" for="first-name">
        <fa-icon [icon]="faUser" class="ico" [ngStyle]="{ 'color': eventData.PrimaryColor}"></fa-icon>Tell us your name
        *
      </label>
      <div class="wrap-input100 rs1-wrap-input100">
        <input id="first-name" class="input100" type="text" name="first-name" placeholder="First name"
          [formControlName]="'firstName'">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!registerForm.get('firstName').valid && registerForm.get('firstName').touched">Required</span>
      </div>
      <div class="wrap-input100 rs2-wrap-input100 ">
        <input class="input100" type="text" name="last-name" placeholder="Last name" [formControlName]="'lastName'">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!registerForm.get('firstName').valid && registerForm.get('lastName').touched">Required</span>
      </div>


      <!-- <label class="label-input100" for="country">
        <fa-icon [icon]="faGlobe" class="ico" [ngStyle]="{ 'color': eventData.PrimaryColor}"></fa-icon>Where are you
        From? *
      </label>
      <div class="wrap-input100 rs1-wrap-input100">
        <input id="country" class="input100" type="text" name="country" placeholder="Country"
          [formControlName]="'country'" [typeahead]="countryList" [isAnimated]="true">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!registerForm.get('country').valid && registerForm.get('country').touched">Required</span>
      </div>
      <div class="wrap-input100 rs2-wrap-input100 ">
        <input class="input100" type="text" name="city" placeholder="City" [formControlName]="'city'">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!registerForm.get('city').valid && registerForm.get('city').touched">Required</span>
      </div> -->


      <label class="label-input100" for="email">
        <fa-icon [icon]="faEnvelope" class="ico" [ngStyle]="{ 'color': eventData.PrimaryColor}"></fa-icon>Enter your
        email *
      </label>
      <div class="wrap-input100">
        <input id="email" class="input100" type="text" name="email" placeholder="Eg. example@email.com"
          [formControlName]="'email'">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!registerForm.get('email').valid && registerForm.get('email').touched">Invalid Email</span>
      </div>

      <!-- <label class="label-input100" for="password">
        <fa-icon [icon]="faKey" class="ico" [ngStyle]="{ 'color': eventData.PrimaryColor}"></fa-icon>Create your
        Password *
      </label>
      <div class="wrap-input100">
        <input id="password" class="input100 d-inline" type="password" name="password" [formControlName]="'password'"
          autocomplete="off" appShowPassword style="width: 73%;">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!registerForm.get('password').valid && registerForm.get('password').touched">Required</span>
      </div> -->

      <div *ngFor="let input of eventData.FormControl" class="w-100">
        <label class="label-input100" [for]="input.Name">
          <!-- <fa-icon [icon]="faHospital" class="ico" [ngStyle]="{ 'color': eventData.PrimaryColor}"></fa-icon> -->
          <i [class]="'ico fas fa-' + input.Icon.toLowerCase()" [ngStyle]="{ 'color': eventData.PrimaryColor}"></i>
          {{input.Title}} <span *ngIf="input.Required == '1'">*</span>
        </label>

        <div class="wrap-input100" *ngIf="input.Type == 'input'">
          <input [id]="input.Name" class="input100" type="text" [name]="input.Name" [placeholder]="input.Title"
            [formControlName]="input.Name">
          <span class="focus-input100"></span>
          <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
            *ngIf="!registerForm.get(input.Name).valid && registerForm.get(input.Name).touched">Required</span>
        </div>

        <div class="wrap-input100" *ngIf="input.Type == 'select'">
          <select [id]="input.Name" class="input100" [name]="input.Name" autocomplete="off"
            [formControlName]="input.Name">
            <option value=null selected disabled>Choose Option</option>
            <option value="1" *ngIf="input['1']">{{ input['1'] }}</option>
            <option value="2" *ngIf="input['2']">{{ input['2'] }}</option>
            <option value="3" *ngIf="input['3']">{{ input['3'] }}</option>
            <option value="4" *ngIf="input['4']">{{ input['4'] }}</option>
            <option value="5" *ngIf="input['5']">{{ input['5'] }}</option>
          </select>
          <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
            *ngIf="!registerForm.get(input.Name).valid && registerForm.get(input.Name).touched">Required</span>
        </div>

      </div>

      <label class="label-input100" for="photo" *ngIf="eventData.UploadPhoto">
        <fa-icon [icon]="faCamera" class="ico" [ngStyle]="{ 'color': eventData.PrimaryColor}"></fa-icon>Profile
        Picture 
        <!-- (optional) -->
      </label>
      <div class="wrap-input100" *ngIf="eventData.UploadPhoto">
        <input class="input100 pt-3 w-75 d-inline-block" type="file" name="photo" placeholder="photo"
          (change)="handleInputChange($event)" id="photo">
        <img [src]="this.imageSrc | liveVimeoUrl" alt="" class="w-25 rounded-circle pt-3 pb-3 pr-3 pl-3">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!registerForm.get('photo').valid && registerForm.get('photo').touched">Required</span>
      </div>

      <p *ngIf="errorMessage" class="mt-3 w-100">
        {{ errorMessage.mesaj }}
      </p>

      <p class="mt-4">
        By singing up you are agreeing to out <a [routerLink]="['/terms-and-conditions']" class="" target="_blank"
          style="color:#044f74;">terms and conditions</a>
      </p>

      <div class="container-contact100-form-btn">
        <button type="submit" class="contact100-form-btn" [disabled]="disabled"
          [ngStyle]="{ 'background-color': eventData.PrimaryColor}">Register</button>
      </div>

      <!-- <p class="w-100 text-center mt-3">Already a Member?
        <a [routerLink]="['']" style="color:#044f74;">Login</a>
      </p> -->

      <div class="footer mt-3" *ngIf="!message">
        <img src="assets/images/registeration-logo.png" class="pl-2 pr-2">
      </div>

    </form>

    <!-- <div class="footer" *ngIf="!message">
      <img src="assets/images/registeration-logo.png" class="w-50 pl-2 pr-2">
    </div> -->

    <div class="w-100 p-3 text-center" *ngIf="message && +message.id != 3">
      <img src="assets/images/mail.gif" alt="mail mark" class="img-responsive">
      <p class="w-100"><b>Thank you for Registeration, Please check your Mail for more info on how to join your next
          event.</b></p>
    </div>

    <p *ngIf="message && +message.id == 3" class="m-5 w-100 text-center font-weight-bold">
      {{ message.mesaj }}
    </p>

    <!-- <div class="container-contact100-form-btn pt-0 mb-5" *ngIf="message">
      <button class="contact100-form-btn" [routerLink]="['']">Login</button>
    </div> -->

  </div>
</div>
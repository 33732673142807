import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TimerService } from '../services/timer.service';
import { EventService } from '../services/event.service';
import { Title } from '@angular/platform-browser';

// import * as introJs from 'intro.js/intro.js';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faUser,
  faAngleDown,
  faCalendarAlt,
  faVideo,
  faLaptopHouse,
  faClone,
  faExpand,
  faCompress,
  faDownload,
  faArchway,
  faInfo,
  faImage,
  faPollH,
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services/auth.service';

import { SwiperOptions } from 'swiper';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from '../messaging.service';
import { DOCUMENT } from '@angular/common';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export declare function openFullscreen();

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  modalRef: BsModalRef;
  activeVideo: string;
  activeOrientaion: string;
  time = new Date();
  timeGMT: any;
  countDown: any;
  meetingCountdown: any;
  eventID: string;
  // introJS = introJs();
  eventStarted: boolean = false;
  eventEnded: boolean = false;
  faUser = faUser;
  name: string;
  profilePic: string;
  activateBooths: boolean = false;
  activateGallery: boolean = false;
  faAngleDown = faAngleDown;
  downloadLinks: any = [];
  title: string;
  @ViewChild('tutorialModal', { static: false })
  tutorialModal: TemplateRef<any>;
  @ViewChild('surveyModal', { static: false }) surveyModal: TemplateRef<any>;
  @ViewChild('feedBack', { static: false }) feedBack: TemplateRef<any>;
  counter: number = 0;
  numberOfBooths: number;
  fullscreen: boolean = this.eventService.isFullscreen();

  rooms = [];

  allData: any;

  faClenderAlt = faCalendarAlt;
  faVideo = faVideo;
  faLaptopHouse = faLaptopHouse;
  faClone = faClone;
  faExpand = faExpand;
  faCompress = faCompress;
  faDownload = faDownload;
  faArchway = faArchway;
  faInfo = faInfo;
  faImage = faImage;
  faPollH = faPollH;

  // dummyAgenda = [
  //   { from: '6:30 PM', to: '07:30 PM', speaker: 'John Due', topic: 'Pharma Intro' },
  //   { from: '7:30 PM', to: '09:00 PM', speaker: 'Mark Schlberg', topic: 'Pharma Chapter I' },
  //   { from: '9 PM', to: '11:00 PM', speaker: 'Felix', topic: 'Pharma Chapter II' },
  //   { from: '11:00 PM', to: '12:30 AM', speaker: 'Omar Magdy', topic: 'Pharma Conclusion' },
  // ]

  detailedAgenda: any;
  enableTutorial: boolean;
  activeTemplate: number;
  enableAgenda: boolean;

  mainHall = {
    primaryColor: '',
    secondaryColor: '',
    backgroundImage: new Image(),
    backgroundVideo: '',
    mobileBackgroundImage: new Image(),
    mobileName: new Image(),
    mobileIntroPic: new Image(),
    mobileAgendaImg: new Image(),
    mobileBoothImg: new Image(),
    mobileAuditoriumImg: new Image(),
    mobileGalleryImg: new Image(),
    startTimeinGMT: new Date('2020-06-25T12:00:00'),
    agenda: '',
    supportChatID: '1eai1914a',
    topMiddleVideo: {
      title: '',
      url: '',
      orientation: 'horizontal',
    },
    centerVideo: {
      title: '',
      url: '',
      orientation: 'horizontal',
    },
    farRightVideo: {
      title: '',
      url: '',
      orientation: 'vertical',
    },
    closeRightVideo: {
      title: '',
      url: '',
      orientation: 'vertical',
    },
    farLeftVideo: {
      title: '',
      url: '',
      orientation: 'vertical',
    },
    closeLeftVideo: {
      title: '',
      url: '',
      orientation: 'vertical',
    },
    introVideo: {
      title: '',
      url: '',
      orientation: 'horizontal',
    },
  };

  slideData = [
    {
      url: 'assets/images/tutorial/main.jpg',
      name: 'Lobby and Lounge',
      desc: 'Lobby and lounge: The virtual lobby is the central point of access for an online event. Participants can get a first impression, interact with other participants and step into the other areas of the online event.',
    },
    {
      url: 'assets/images/tutorial/auditorium.jpg',
      name: 'Auditorium',
      desc: 'Auditorium: We incorporate live or on-demand videos, with or without slides, with subtitles and a translation on request – surveys, feedback and online tests can also be integrated.',
    },
    // {
    //   url: 'assets/images/tutorial/booths.jpg',
    //   name: "Meeting Rooms",
    //   desc: "To join the summit that you signed up for you just have to visit this."
    // },
    {
      url: 'assets/images/tutorial/agenda.jpg',
      name: 'Agenda',
      desc: 'You can check the Schedule of the event through the Agenda.',
    },
    // {
    //   url: 'assets/images/tutorial/intro-video.jpg',
    //   name: "Introduction Video",
    //   desc: 'You can also watch a video about the portal.'
    // },
    // {
    //   url: 'assets/images/tutorial/reception.jpg',
    //   name: "Reception",
    //   desc: 'This is the receptionist, You can ask her anything'
    // }
  ];

  menuItemsArray: any[] = [];

  menuConfig: any = {
    animation: 'collapse',
    offset: { top: 30 },
    closeOnCLick: true,
  };

  config: SwiperOptions = {
    autoHeight: true,
    allowTouchMove: true,
    slidesPerView: 1,
    simulateTouch: false,
  };

  auditoriumRoom: any;
  faTimesCircle = faTimesCircle;

  @ViewChild('BayerAPIModal') BayerAPIModal: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    private timerService: TimerService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    private toastr: ToastrService,
    private messageService: MessagingService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.eventID = this.route.snapshot.params['eventID'];

    if (!localStorage.getItem('session')) {
      localStorage.clear();
      this.router.navigate(['']);
      return;
    }

    this.eventService.getEvent().subscribe(
      (response: any) => {
        console.log(response);

        if (response.events.guid != this.eventID) {
          localStorage.clear();
          this.router.navigate(['']);
          return;
        }

        this.eventService.currentEvent = response;
        this.allData = { ...response };

        this.title = response.events.name;
        this.titleService.setTitle(response.events.name + ' - Cloudvie');
        this.detailedAgenda = response.agenda[''];
        // this.mainHall.backgroundImage = new Image();

        this.mainHall.backgroundImage.src =
          'assets/' +
          response.events.name.trim().replace(/\s+/g, '-').toLowerCase() +
          '/Main-Hall.jpg';
        this.mainHall.backgroundVideo =
          'assets/' +
          response.events.name.trim().replace(/\s+/g, '-').toLowerCase() +
          '/Main-Hall.mp4';
        this.mainHall.mobileBackgroundImage.src =
          'assets/' +
          response.events.name.trim().replace(/\s+/g, '-').toLowerCase() +
          '/mobile-mainhall.jpg';
        this.mainHall.mobileName.src =
          'assets/' +
          response.events.name.trim().replace(/\s+/g, '-').toLowerCase() +
          '/mobile-name.png';
        this.mainHall.mobileIntroPic.src =
          'assets/' +
          response.events.name.trim().replace(/\s+/g, '-').toLowerCase() +
          '/mobile-intro.png';
        this.mainHall.mobileAgendaImg.src =
          'assets/' +
          response.events.name.trim().replace(/\s+/g, '-').toLowerCase() +
          '/mobile-agenda.png';
        this.mainHall.mobileBoothImg.src =
          'assets/' +
          response.events.name.trim().replace(/\s+/g, '-').toLowerCase() +
          '/mobile-booth-btn.png';
        this.mainHall.mobileAuditoriumImg.src =
          'assets/' +
          response.events.name.trim().replace(/\s+/g, '-').toLowerCase() +
          '/mobile-auditorium-btn.png';
        this.mainHall.mobileGalleryImg.src =
          'assets/' +
          response.events.name.trim().replace(/\s+/g, '-').toLowerCase() +
          '/mobile-gallery-btn.png';
        this.mainHall.agenda = response.events.agenda;
        this.mainHall.centerVideo.url = response.events.centerVideo;
        this.mainHall.closeLeftVideo.url = response.events.closeLeftVideo;
        this.mainHall.closeRightVideo.url = response.events.closeRightVideo;
        this.mainHall.farLeftVideo.url = response.events.farLeftVideo;
        this.mainHall.farRightVideo.url = response.events.farRightVideo;
        this.mainHall.introVideo.url = response.events.introVideo;
        this.mainHall.topMiddleVideo.url = response.events.topMiddleVideo;
        this.mainHall.primaryColor = response.events.primaryColor;
        this.mainHall.secondaryColor = response.events.secondaryColor;
        this.mainHall.supportChatID = response.events.supportChatID;
        this.numberOfBooths = response.booths.length;
        this.enableTutorial = response.events.enableTutorial;
        this.eventService.countTill5Minutes(
          response.events.startTimeinGMT,
          response.events.endTimeinGMT
        );
        // this.eventService.showMessageBeforeEnding();

        this.authService.callRedirectEvery10Minutes(this.eventID);

        // if (this.numberOfBooths == 1) {
        //   this.router.navigate(['/booth', this.eventID, 'booth1']);
        // }
        this.name =
          localStorage.getItem('firstName') +
          ' ' +
          localStorage.getItem('lastName');
        this.profilePic = localStorage.getItem('profile')
          ? localStorage.getItem('profile')
          : null;
        this.activateBooths = response.booths.length ? true : false;
        this.activateGallery =
          response.gallery && response.gallery.length ? true : false;

        // this.menuItemsArray.push({ title: 'Go Fullscreen', link: '' });
        // this.getDownloadMaterial(response.booths, response.events.name);
        this.eventService.showFooter.next(true);
        this.eventService.openPublicChat.next(true);

        // Firebase Notification
        this.messageService.requestPermission();

        // Isolating the rooms
        this.rooms = response.rooms.filter((room) => +room.type == 1);
        console.log(this.rooms);

        // this.menuItemsArray.push({ title: 'Plenary Session', link: '/plenary-session/' + this.eventID });
        // this.menuItemsArray.push({ title: 'Auditorium', link: '' });

        if (this.allData.support) {
          this.allData.support.forEach((room) => {
            this.menuItemsArray.push({
              title: room.name,
              link: '/' + 'support' + '/' + this.eventID,
            });
          });
        }

        this.rooms.forEach((room) => {
          if (room.type != '1') {
            this.menuItemsArray.push({
              title: room.name,
              link: '/' + room.name + '/' + this.eventID,
            });
          } else {
            this.auditoriumRoom = room;
          }
        });
        if (this.activateBooths) {
          if (this.numberOfBooths == 1) {
            this.menuItemsArray.push({
              title: response.booths[0].rooms_name,
              link: '/booth/' + this.eventID + '/booth1',
            });
          } else {
            this.menuItemsArray.push({
              title: 'Booths',
              link: '/booths/' + this.eventID,
            });
          }
        }

        if (this.activateGallery) {
          this.menuItemsArray.push({
            title: response.gallery[0].rooms_name,
            link: '/gallery/' + this.eventID,
          });
        }

        this.menuItemsArray.push({
          title: 'Agenda',
          link: '/agenda/' + this.eventID,
        });

        if (this.enableTutorial) {
          this.menuItemsArray.push({ title: 'Tutorial', link: '' });
        }

        // Only for Jivi
        // this.menuItemsArray.push({ title: 'API', link: '' });

        // this.menuItemsArray.push({ title: 'Survey', link: '' });

        this.getDownloadMaterial(response.booths, response.events.name);

        // console.log(roomId);

        // getting the mainhall Id
        let roomId = response.rooms.find((room) => +room.type == 3)
          ? response.rooms.find((room) => +room.type == 3).id
          : this.redirectToSession(this.rooms[0].guid);

        this.activeTemplate = response.events.template;

        this.timerService.sendActivity(roomId);
        // console.log(localStorage.getItem('chatweeSession'));
        // console.log(document.cookie);

        setInterval(() => {
          this.time = new Date();
          this.timeGMT = this.adjustForTimezone(new Date());
          // this.toastr.success('', 'Toastr fun!');
        }, 1000);

        setTimeout(() => {
          // Go Directly to the Auditorium
          // this.redirectToSession(this.rooms[0].guid);
          // return

          this.loading = false;
          // this.introJS.start();
          // this.modalRef = this.modalService.show(this.tutorialModal, { class: 'full-screen-modal' });
          if (localStorage.getItem('skipTutorial')) {
          } else {
            if (this.enableTutorial) {
              // this.introJS.start();
              this.modalRef = this.modalService.show(this.tutorialModal, {
                class: 'full-screen-modal',
              });
              localStorage.setItem('skipTutorial', 'true');
            } else {
            }
          }
        }, 2000);

        // console.log(this.adjustForTimezone(new Date(response.events.startTimeinGMT * 1000)));
        // console.log(this.adjustForTimezone(new Date));
        // console.log(this.adjustForTimezone(new Date).getTime() / 1000);
        // console.log((response.events.startTimeinGMT - this.adjustForTimezone(new Date).getTime() / 1000) < 0);
        // console.log(new Date((response.events.endTimeinGMT * 1000)).toUTCString());
        // console.log(new Date("2020-06-25T12:00:00"));
        // console.log(this.adjustForTimezone(new Date((response.events.endTimeinGMT * 1000))));

        // this.mainHall.startTimeinGMT

        let zeroSeconds: boolean = false;

        this.timerService
          .timer(new Date(response.events.startTimeinGMT * 1000))
          .subscribe((respnseTime) => {
            this.countDown = respnseTime;

            if (
              this.adjustForTimezone(
                new Date(response.events.startTimeinGMT * 1000)
              ).getTime() /
                1000 -
                this.adjustForTimezone(new Date()).getTime() / 1000 <
              0
            ) {
              this.eventStarted = true;

              if (!zeroSeconds) {
                zeroSeconds = true;
                this.menuItemsArray.push({
                  title: this.auditoriumRoom.name,
                  link: '/' + this.auditoriumRoom.name + '/' + this.eventID,
                });
              }
            }
            if (
              this.adjustForTimezone(
                new Date(response.events.endTimeinGMT * 1000)
              ).getTime() /
                1000 -
                this.adjustForTimezone(new Date()).getTime() / 1000 <
              0
            ) {
              this.eventEnded = true;
            }
            if (
              this.adjustForTimezone(
                new Date(response.events.endTimeinGMT * 1000)
              ).getTime() /
                1000 -
                this.adjustForTimezone(new Date()).getTime() / 1000 <
              30000
            ) {
              // Message before ending
              // this.eventService.showMessageBeforeEnding();
            }

            // console.log(this.adjustForTimezone(new Date(response.events.endTimeinGMT * 1000)).getTime());
          });

        // console.log(+response.events.startTimeinGMT + 4140000);
        this.timerService
          .timer(
            new Date((+response.events.startTimeinGMT + 4140 + 900) * 1000)
          )
          .subscribe((respnseTime) => {
            // console.log(respnseTime);
            this.meetingCountdown = respnseTime;
          });

        let script = this._renderer2.createElement('script');
        script.type = `text/javascript`;
        script.text = `
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = '${response.events.supportChatID}';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
      `;

        this._renderer2.appendChild(this._document.body, script);
      },
      (error) => {
        console.log(error);
      }
    );

    this.modalService.onHide.subscribe((event) => {
      console.log(event);
      this.counter = 0;
    });

    // console.log(this.introJS);
  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
  }

  loadBackground(url) {
    const imageUrl = url;
    let bgElement = document.querySelector('body');
    let preloaderImg = document.createElement('img');
    preloaderImg.src = imageUrl;

    preloaderImg.addEventListener('load', (event) => {
      bgElement.style.backgroundImage = `url(${imageUrl})`;
      preloaderImg = null;
    });
  }

  openModal(videoModal: TemplateRef<any>, link, orientation) {
    this.activeVideo = link;
    if (orientation == 'horizontal') {
      this.modalRef = this.modalService.show(videoModal, { class: 'bg-modal' });
    } else {
      this.modalRef = this.modalService.show(videoModal);
    }
    // console.log(link);
  }

  openFeedback(feedBack: TemplateRef<any>) {
    this.modalRef = this.modalService.show(feedBack, { class: 'agenda-modal' });
  }

  openTutorial(tutorialModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tutorialModal, {
      class: 'full-screen-modal',
    });
  }

  openSurvey(surveyModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(surveyModal, { class: 'bg-modal' });
  }

  openSupportChat() {
    // window.open(this.mainHall.supportChatID, 'name', 'width=500,height=500');
    // console.log('here');

    // var ua = navigator.userAgent.toLowerCase();
    // if (ua.indexOf('safari') != -1) {
    //   if (ua.indexOf('chrome') > -1) {
    //     this.eventService.openChat.next({
    //       name: 'Reception',
    //       url: this.mainHall.supportChatID
    //     })
    //   } else {
    //     window.open(this.mainHall.supportChatID, 'name', 'width=500,height=500');
    //   }
    // }
    this.eventService.openChat.next({
      name: 'Reception',
      url: this.mainHall.supportChatID,
    });
  }

  getDownloadMaterial(booths, name) {
    // console.log(booths);

    const mobileDownloads = [];

    booths.forEach((booth) => {
      // console.log(booth);

      if (!booth.materials.length) {
        booth.materials = [];
      }

      booth.materials.forEach((material) => {
        this.downloadLinks.push({
          name: material,
          url:
            'assets/' +
            name.trim().replace(/\s+/g, '-').toLowerCase() +
            '/' +
            material,
        });

        mobileDownloads.push({
          title: material,
          link:
            'assets/' +
            name.trim().replace(/\s+/g, '-').toLowerCase() +
            '/' +
            material,
        });
      });

      // this.downloadLinks.push({
      //   name: booth.subject,
      //   url: "assets/" + name.trim().replace(/\s+/g, '-').toLowerCase() + '/' + String(booth.subject) + '.pdf'
      // })
    });

    if (mobileDownloads.length) {
      this.menuItemsArray.push({
        title: 'Download Materials',
        link: '#',
        subItems: mobileDownloads,
      });
    }

    // console.log(this.downloadLinks);
  }

  signout() {
    this.authService.logout().subscribe(
      (response) => {
        this.router.navigate(['/login']);
      },
      (error) => {
        this.router.navigate(['/login']);
      }
    );
  }

  onMenuClose() {
    // console.log("menu closed");
  }
  onMenuOpen() {
    // console.log("menu Opened");
  }
  onItemSelect(item: any) {
    // console.log(item);
    if (item.title == 'Go Fullscreen') {
      openFullscreen();
    } else if (item.title == 'Tutorial') {
      this.modalRef = this.modalService.show(this.tutorialModal, {
        class: 'full-screen-modal',
      });
    } else if (item.title == 'Download Materials') {
    } else if (item.title == 'Survey') {
      this.modalRef = this.modalService.show(this.surveyModal, {
        class: 'bg-modal',
      });
    } else if (item.link.includes('_')) {
      console.log('download Links');
      window.open(item.link, '_blank');
    } else if (item.title == 'API') {
      this.openBayerModal(this.BayerAPIModal);
    } else {
      this.router.navigate([item.link]);
    }
  }

  openFullScreen() {
    var elem: any = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }

    this.fullscreen = true;
  }

  closeFullScreen() {
    var elem: any = document;

    if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.mozCancelFullScreen) {
      elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) {
      elem.webkitExitFullscreen();
    } else if (elem.msExitFullscreen) {
      elem.msExitFullscreen();
    }

    this.fullscreen = false;
  }

  redirectToSession(guid?: string) {
    this.loading = true;

    const name = this.rooms.find((room) => room.guid == guid).name;
    console.log(name);
    // this.loading = false;
    // this.router.navigate(['/' + this.eventService.convertSpacesintoDashes(name).toLowerCase(), this.eventID]);
    // return;

    this.eventService.redirectToSession(guid).subscribe(
      (response) => {
        // console.log(response);
        const name = this.rooms.find((room) => room.guid == guid).name;
        console.log(name);
        this.loading = false;

        if (+this.countDown.seconds >= 0) {
          this.toastr.error(
            '',
            'Opens in ' +
              (+this.countDown.days * 24 + +this.countDown.hours) +
              'h' +
              +this.countDown.minutes +
              'm',
            { timeOut: 1000 }
          );
        } else {
          this.router.navigate([
            '/' + this.eventService.convertSpacesintoDashes(name).toLowerCase(),
            this.eventID,
          ]);
        }
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.toastr.error('', error.error, {
          closeButton: true,
          disableTimeOut: true,
        });
      }
    );
  }

  openBayerModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'bayerModal modal-dialog-centered' })
    );
  }

  ngOnDestroy() {
    this.timerService.sendEndActivity(this.timerService.endTimer());
  }

  agena = {
    rooms_name: [
      {
        speakers_name: 'speakers_name',
        subject: 'subject',
        time_start: 'time_start',
        time_end: 'time_end',
      },
      {
        speakers_name: 'speakers_name',
        subject: 'subject',
        time_start: 'time_start',
        time_end: 'time_end',
      },
    ],
    'plenary Session': [
      {
        speakers_name: 'Dr. Roushdy',
        subject: 'Subject example',
        time_start: '16:15',
        time_end: '16:50',
      },
      {
        speakers_name: 'Dr. Roushdy',
        subject: 'Subject example',
        time_start: '16:15',
        time_end: '16:50',
      },
    ],
  };
}

<div class="container-contact100">
  <div class="wrap-contact100">
    <div class="footer" *ngIf="!message">
      <img src="assets/images/registeration-logo.png" class="w-50 pl-2 pr-2">
    </div>
    <form class="contact100-form " [formGroup]="resetForm" (ngSubmit)="submitForm()" *ngIf="!successMessage">
      <span class="contact100-form-title">
        Reset Password
      </span>

      <label class="label-input100" for="password">Enter your password *</label>
      <div class="wrap-input100">
        <input id="password" class="input100" type="password" name="password" [formControlName]="'password'"
          autocomplete="off">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!resetForm.get('password').valid && resetForm.get('password').touched">Required</span>
      </div>


      <label class="label-input100" for="confirm">Confirm password *</label>
      <div class="wrap-input100">
        <input id="confirm" class="input100" type="password" name="confirm" [formControlName]="'confirmPassword'"
          autocomplete="off">
        <span class="focus-input100"></span>
        <span class="text-white bg-danger float-left mb-0 pl-2 pr-2"
          *ngIf="!resetForm.get('confirmPassword').valid && resetForm.get('confirmPassword').touched || (resetForm.get('password').value !== resetForm.get('confirmPassword').value)">Passwords
          Don't Match</span>
      </div>

      <p *ngIf="errroMessage" class="text-danger font-weight-bold">{{ errroMessage }}</p>

      <div class="container-contact100-form-btn">
        <button type="submit" class="contact100-form-btn" [disabled]="disabled">Reset Password</button>
      </div>

    </form>



    <p *ngIf="successMessage" class="m-5 w-100">
      {{ successMessage }}
    </p>

    <div class="container-contact100-form-btn pt-0 mb-5" *ngIf="successMessage">
      <button class="contact100-form-btn" [routerLink]="['']">Login</button>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss']
})
export class ExternalLinkComponent implements OnInit {
  eventID: string;
  boothName: string;
  externalLink

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {

      this.boothName = params.boothID;
      // console.log(this.boothName);
      this.eventService.getEvent().subscribe((response: any) => {
        // console.log(response.booths);
        this.externalLink = response.booths.find(booth => booth.name.toLowerCase() == this.boothName).ExternalLinks;
        // console.log(this.externalLink);
      })
    })
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, interval, Subscription } from 'rxjs';
import { Router } from '@angular/router';
const shajs = require('sha.js');

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headers = new HttpHeaders({
    'api-key': '9Ld0a26eb6cde16sZtAso3cg9XBfmy2N',
  });

  intervalObservable: Subscription;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) {}

  register(body) {
    // body.token = environment.token;
    return this.http.post(environment.SERVER + 'users/register', body, {
      headers: this.headers,
    });
  }

  login(body) {
    // body.token = environment.token;
    return this.http.post(environment.SERVER + 'users/login', body, {
      headers: this.headers,
    });
  }

  logout() {
    const timeNowinGMT =
      new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
    const string =
      environment.merchantKey + ':' + timeNowinGMT + environment.API_KEY;
    const token = shajs('sha256').update(string).digest('hex');

    const body = {
      session_idu: localStorage.getItem('session'),
      timestamp: timeNowinGMT,
      token: token,
    };

    localStorage.clear();
    this.cookieService.deleteAll();
    return this.http.post(environment.SERVER + 'users/logout', body, {
      headers: this.headers,
    });
  }

  forgetPassword(body) {
    return this.http.post(environment.SERVER + 'users/resetpass', body, {
      headers: this.headers,
    });
  }

  resetPassword(body) {
    return this.http.post(environment.SERVER + 'users/passchanged', body, {
      headers: this.headers,
    });
  }

  isLoggedIn(body) {
    return this.http.post(environment.SERVER + 'users/islogin', body, {
      headers: this.headers,
    });
  }

  getRegsiterLink() {
    return this.http.get(environment.SERVER + 'events/inviteLink', {
      headers: this.headers,
    });
  }

  redirectIfAuthenticated(eventID) {
    if (localStorage.getItem('session')) {
      //Only for logged in users
      const timeNowinGMT =
        new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
      const string =
        environment.merchantKey + ':' + timeNowinGMT + environment.API_KEY;
      const token = shajs('sha256').update(string).digest('hex');

      const body = {
        session_idu: localStorage.getItem('session'),
        timestamp: timeNowinGMT,
        token: token,
      };

      this.http
        .post(environment.SERVER + 'users/redirect', body, {
          headers: this.headers,
        })
        .subscribe(
          (response: any) => {
            // console.log(response);

            if (response.redirect == '2') {
              // redirect = 2 for refreshing the data
              // this.router.navigate(['/event', eventID]);
              location.assign('/event/' + eventID);
            } else if (response.redirect == '1') {
              // redirect = 1 for banning the users
              this.logout().subscribe(
                (response) => {
                  // this.router.navigate(['/login']);
                  location.assign('/login');
                },
                (error) => {
                  location.assign('/login');
                  // this.router.navigate(['/login']);
                }
              );
            } else if (response.redirect == '3') {
              this.router.navigate(['/boths/' + eventID]);
            } else if (response.redirect == '4') {
              this.router.navigate(['/auditorium/' + eventID]);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
    }
  }

  callRedirectEvery10Minutes(eventID) {
    this.redirectIfAuthenticated(eventID);
    if (this.intervalObservable) {
      this.intervalObservable.unsubscribe();
    }

    this.intervalObservable = interval(60000).subscribe(() => {
      // console.log("30 seconds");
      this.redirectIfAuthenticated(eventID);
    });
  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
  }
}

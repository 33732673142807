<!-- <button
  [disabled]="opening"
  (click)="openChat()"
  class="btn btn-success">
  <span *ngIf="!opening"> open chat </span>
  <span *ngIf="opening">Loading chat ...</span>
</button>
<button
  [disabled]="closing"
  (click)="closeChat()"
  class="btn btn-danger">
  <span *ngIf="!closing"> close chat </span>
  <span *ngIf="closing">Closing chat ...</span>
</button> -->
<div #chatWindow id="chatwee-widget"></div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, TimeInterval } from 'rxjs';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const shajs = require('sha.js');

export interface Time {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  timeinSeconds: number = 0;
  timeInterval: any;
  tenMinuteInterval: any;
  currentActivityId: any;


  headers = new HttpHeaders({
    'api-key': '9Ld0a26eb6cde16sZtAso3cg9XBfmy2N',
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient,
  ) { }

  private createTimeObject(date: Date): Time {

    const now = new Date().getTime();
    const distance = new Date(date).getTime() - now;

    let time: Time = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    time.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    time.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    time.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    time.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return time;
  }

  timer(date: Date): Observable<Time> {
    return interval(1000).pipe(map(() => this.createTimeObject(date)))
  }

  startTimer() {
    this.timeinSeconds = 0;
    console.log("Timer Started: " + this.timeinSeconds);
    this.sendEvery10Minutes();
    this.timeInterval = setInterval(() => {
      this.timeinSeconds++;
      // console.log(this.timeinSeconds); 
    }, 1000);
  }

  sendEvery10Minutes() {
    this.tenMinuteInterval = setInterval(() => {
      console.log("10 Minutes Mark");
      this.sendEndActivity(this.timeinSeconds, false);
    }, 600000)
  }

  endTimer() {
    console.log("Timer Ended: " + this.timeinSeconds);
    clearInterval(this.timeInterval);
    clearInterval(this.tenMinuteInterval);
    return this.timeinSeconds;
  }

  sendActivity(roomID) {

    this.startTimer();

    const timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
    const string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
    const token = shajs('sha256').update(string).digest('hex');

    const body = {
      "session_idu": localStorage.getItem('session'),
      "timestamp": timeNowinGMT,
      "token": token,
      "rooms": roomID,
      "partners": "17",
      "speakers": "1"
    }

    console.log(body)

    this.http.post(environment.SERVER + 'users/activities', body, { headers: this.headers }).subscribe((response: any) => {
      console.log(response);
      console.log("Current ID is " + response.activities_id);
      this.currentActivityId = response.activities_id;
    }, error => {
      console.log(error);
    });
  }

  sendEndActivity(duration: any, reset = true) {

    console.log(reset);
    if (reset) {
      this.timeinSeconds = 0;
    }


    const timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
    const string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
    const token = shajs('sha256').update(string).digest('hex');

    const body = {
      "session_idu": localStorage.getItem('session'),
      "timestamp": timeNowinGMT,
      "token": token,
      "id": this.currentActivityId,
      "duration": duration,
      "partners": "17",
      "speakers": "1"
    }

    console.log(body);

    this.http.post(environment.SERVER + 'users/duration', body, { headers: this.headers }).subscribe((response: any) => {
      console.log(response);
    }, error => {
      console.log(error);
    });

  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Title } from '@angular/platform-browser';
import {
  faBriefcase,
  faEnvelope,
  faKey,
  faPhone,
  faGlobe,
  faCamera,
  faSortAmountDown,
  faHospital,
  faUser,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
const shajs = require('sha.js');
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EventService } from '../services/event.service';
import { title } from 'process';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  selected: string;
  ide: string;
  pwd: string;
  imageSrc: string = '';
  disabled: boolean = false;
  message: any;
  errorMessage: any;
  resizedBase64: any = '';
  faBriefcase = faBriefcase;
  faEnvelope = faEnvelope;
  faKey = faKey;
  faPhone = faPhone;
  faGlobe = faGlobe;
  faCamera = faCamera;
  faSortAmountDown = faSortAmountDown;
  faHospital = faHospital;
  faUser = faUser;
  faDate = faCalendar;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Egypt, CountryISO.SaudiArabia];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  loading: boolean = true;
  eventData: any;

  registerForm: FormGroup = new FormGroup({
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl('123456', Validators.required),
    // 'country': new FormControl(null, Validators.required),
    // 'city': new FormControl(null, Validators.required),
  });

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Register' + ' - Cloudvie');

    this.route.params.subscribe((params: Params) => {
      this.ide = params.ide;
      this.pwd = params.pwd;

      this.eventService
        .getEventData(this.ide, this.pwd)
        .subscribe((response: any) => {
          console.log(response);
          this.eventData = response;

          console.log(this.eventData.FormControl);

          // Add Dynamic elements to the form
          this.eventData.FormControl.forEach((element) => {
            element.Icon = this.capitalize(element.Icon);
            this.registerForm.addControl(
              element.Name,
              new FormControl(
                null,
                Number(element.Required) ? Validators.required : []
              )
            );
          });

          let timeNowinGMT =
            new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
          let string =
            environment.merchantKey + ':' + timeNowinGMT + environment.API_KEY;
          let token = shajs('sha256').update(string).digest('hex');

          this.registerForm.addControl('ide', new FormControl(this.ide));
          this.registerForm.addControl('pwd', new FormControl(this.pwd));
          this.registerForm.addControl('token', new FormControl(token));
          this.registerForm.addControl(
            'timestamp',
            new FormControl(timeNowinGMT)
          );
          this.registerForm.addControl(
            'photo',
            new FormControl(this.resizedBase64)
          );

          // console.log(this.registerForm);

          this.loading = false;
        });
    });
  }

  submitForm() {
    this.registerForm.markAllAsTouched();
    console.log(this.registerForm.value);
    console.log(this.registerForm.status);

    if (this.registerForm.status === 'VALID') {
      this.disabled = true;

      let timeNowinGMT =
        new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
      let string =
        environment.merchantKey + ':' + timeNowinGMT + environment.API_KEY;
      let token = shajs('sha256').update(string).digest('hex');

      if (this.imageSrc) {
        this.compressImage(this.imageSrc, 100, 100).then((compressed) => {
          this.resizedBase64 = compressed;

          this.registerForm.patchValue({
            photo: this.resizedBase64,
          });

          let body = this.registerForm.value;

          console.log(body);
          // this.router.navigate(['/event', '1234']);
          // return

          this.auth.register(body).subscribe(
            (response: any) => {
              // console.log(response);
              this.registerForm.reset();
              this.disabled = false;
              // alert(response);
              this.message = response;
              // this.router.navigate(['/event', '1234']);
            },
            (error) => {
              this.disabled = false;
              // this.registerForm.reset();
              this.errorMessage = error;
              console.log(error);
            }
          );
        });
      } else {
        let body = this.registerForm.value;

        console.log(body);
        // this.router.navigate(['/event', '1234']);
        // return

        this.auth.register(body).subscribe(
          (response: any) => {
            // console.log(response);
            this.registerForm.reset();
            this.disabled = false;
            // alert(response);
            this.message = response;
            // this.router.navigate(['/event', '1234']);
          },
          (error) => {
            this.disabled = false;
            // this.registerForm.reset();
            this.errorMessage = error;
            console.log(error);
          }
        );
      }
    }
  }

  // onItemSelect(item: any) {
  //   console.log(item);
  //   console.log(this.selectedItems);
  // }
  // onSelectAll(items: any) {
  //   console.log(items);
  // }

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();

    if (file.size > 500000) {
      this.registerForm.patchValue({
        photo: null,
      });
      alert('Maximum file size allowed is 500KB');
      this.imageSrc = null;
      return;
    }

    if (!file.type.match(pattern)) {
      this.registerForm.patchValue({
        photo: null,
      });
      alert('invalid format');
      this.imageSrc = null;
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
    // console.log(this.imageSrc);
  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
  }

  compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      };
      img.onerror = (error) => rej(error);
    });
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  countryList = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antigua &amp; Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia &amp; Herzegovina',
    'Botswana',
    'Brazil',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Cape Verde',
    'Cayman Islands',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Congo',
    'Cook Islands',
    'Costa Rica',
    'Cote D Ivoire',
    'Croatia',
    'Cruise Ship',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Polynesia',
    'French West Indies',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kuwait',
    'Kyrgyz Republic',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macau',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Pierre &amp; Miquelon',
    'Samoa',
    'San Marino',
    'Satellite',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'South Africa',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'St Kitts &amp; Nevis',
    'St Lucia',
    'St Vincent',
    'St. Lucia',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    "Timor L'Este",
    'Togo',
    'Tonga',
    'Trinidad &amp; Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks &amp; Caicos',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'Uruguay',
    'Uzbekistan',
    'Venezuela',
    'Vietnam',
    'Virgin Islands (US)',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ];
}

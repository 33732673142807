<div class="my-container">
    <ng-template #customLoadingTemplate>
    </ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop:true }"
        [template]="customLoadingTemplate"></ngx-loading>
</div>

<!-- <app-header></app-header> -->
<!-- Header Start -->
<div class="container-fluid header">

    <div class="logo h-100 d-inline-block" dropdown>
        <a [routerLink]="['/event', eventID]" *ngIf="activeTemplate != 4"><img src="assets/images/Logo-colors-short.png"
                alt="Cloudvie Logo" id="logo"></a>

        <a [routerLink]="['/event', eventID]" *ngIf="activeTemplate == 4"><img src="assets/images/bayer-logo.png"
                alt="Bayer Logo" style="height: 28px;" id="logo"></a>

        <h6 [routerLink]="['/support', eventID]" class="desktop-only"
            *ngIf="allData &&  allData.support && allData.support?.length">
            <fa-icon [icon]="faInfo" class="mr-1 ml-0"></fa-icon>
            {{ allData.support[0].name }}
        </h6>

        <!-- <h6 [routerLink]="['/auditorium', eventID]" *ngIf="countDown && +countDown.seconds < 0" class="desktop-only">
            <fa-icon [icon]="faVideo" class=" desktop-only mr-1 ml-0"></fa-icon>
            Auditorium
        </h6> -->

        <!-- <h6 [routerLink]="['/plenary-session', eventID]" *ngIf="countDown && +countDown.seconds < 0" class="desktop-only">
      <fa-icon [icon]="faVideo" class=" desktop-only mr-1 ml-0"></fa-icon>
      Plenary Session
    </h6> -->

        <!-- *ngIf="+countDown.seconds < 0" (click)="redirectToSession()" -->

        <!-- *ngIf="activateBooths" -->
        <h6 [routerLink]="['/booths', eventID]" class="desktop-only"
            *ngIf="countDown && +countDown.days <= 0 && +countDown.hours <= 0 && activateBooths">
            <fa-icon [icon]="faArchway" class=" desktop-only mr-1 ml-0"></fa-icon>
            Booths
        </h6>

        <h6 [routerLink]="['/gallery', eventID]" class="desktop-only" *ngIf="allData && allData.gallery?.length">
            <fa-icon [icon]="faImage" class="mr-1 ml-0"></fa-icon>
            {{ allData.gallery[0].name }}
        </h6>


        <!-- [routerLink]="['/agenda', eventID]" (click)="openFeedback(feedBack)" -->
        <h6 [routerLink]="['/agenda', eventID]" class="desktop-only">
            <fa-icon [icon]="faClenderAlt" class=" desktop-only mr-1 ml-0"></fa-icon>
            Agenda
        </h6>

        <h6 class="vertical-center m-0 desktop-only" dropdownToggle aria-controls="dropdown-download"
            style="cursor: pointer;" *ngIf="downloadLinks.length">
            <fa-icon [icon]="faDownload" class=" desktop-only mr-1 ml-0"></fa-icon>
            Download Material
        </h6>

        <h6 class="vertical-center m-0 desktop-only" style="cursor: pointer;" (click)="openTutorial(tutorialModal)"
            *ngIf="enableTutorial">
            <fa-icon [icon]="faClone" class=" desktop-only mr-1 ml-0"></fa-icon>
            Tutorial
        </h6>

        <!-- <h6 class="vertical-center m-0 desktop-only" style="cursor: pointer;" (click)="openSurvey(surveyModal)">
      <fa-icon [icon]="faPollH" class=" desktop-only mr-1 ml-0"></fa-icon>
      Survey
    </h6> -->

        <!-- <h6 class="vertical-center m-0 desktop-only" style="cursor: pointer;" (click)="openBayerModal(BayerAPIModal)"
      *ngIf="activeTemplate == 4">
      <fa-icon [icon]="faPollH" class=" desktop-only mr-1 ml-0"></fa-icon>
      API
    </h6> -->

        <h6 class="vertical-center m-0 desktop-only" style="cursor: pointer;" (click)="openFullScreen()"
            *ngIf="!fullscreen && activeTemplate != 4">
            <fa-icon [icon]="faExpand" class=" desktop-only mr-1 ml-0"></fa-icon>
            Go Fullscreen
        </h6>

        <h6 class="vertical-center m-0 desktop-only" style="cursor: pointer;" (click)="closeFullScreen()"
            *ngIf="fullscreen && activeTemplate != 4">
            <fa-icon [icon]="faCompress" class=" desktop-only mr-1 ml-0"></fa-icon>
            Exit Fullscreen
        </h6>

        <ul id="dropdown-download" *dropdownMenu class="dropdown-menu desktop-only" role="menu"
            aria-labelledby="button-animated">
            <li role="menuitem" *ngFor="let downloadLink of downloadLinks">
                <a class="dropdown-item" [href]="this.downloadLink.url" target="_blank" class="dropdown-item" download>{{
          downloadLink.name }}</a>
            </li>
        </ul>

        <cuppa-slidemenu [menulist]="menuItemsArray" (open)="onMenuOpen()" (close)="onMenuClose()"
            (onItemSelect)="onItemSelect($event)" class="mobile-only" [config]="menuConfig"></cuppa-slidemenu>

    </div>

    <div class="user-pic float-right helper-div h-100">
        <span class="helper"></span>
        <fa-icon [icon]="faUser" class="text-white vertical-center" *ngIf="!profilePic"></fa-icon>
        <img [src]="profilePic" alt="profile picture" class="rounded-circle desktop-only" style="width: 1vw;"
            *ngIf="profilePic">
        <img [src]="profilePic" alt="profile picture" class="rounded-circle mobile-only" style="width: 5vw;"
            *ngIf="profilePic">
    </div>
    <div class="user-name float-right text-white helper-div h-100 mr-3" dropdown>
        <span class="helper"></span>
        <h6 class="vertical-center m-0" dropdownToggle aria-controls="dropdown-animated" style="cursor: pointer;">
            {{name}}
        </h6>

        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
            <li role="menuitem">
                <a class="dropdown-item" (click)="signout()">Logout</a>
            </li>
        </ul>

    </div>
</div>

<!-- Header End -->

<!-- [ngStyle]="{ 'background-image': 'url(' + mainHall.backgroundImage.src + ')'}" -->
<div *ngIf="!loading" [class]="'container-fluid p-0 m-0 main-hall desktop-only template' + activeTemplate">

    <video [poster]="mainHall.backgroundImage.src" loop autoplay muted class="background-video">
        <source [src]="mainHall.backgroundVideo" type="video/mp4">
    </video>


    <div class=" top-middle-video hover" (click)="openModal(videoModal, mainHall.topMiddleVideo.url, 'horizontal')">
        <iframe [src]="mainHall.topMiddleVideo.url + '?loop=1&autoplay=1' | vimeoUrl" width="100%" height="100%"
            frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div class="center-video hover" *ngIf="mainHall.centerVideo.url"
        (click)="openModal(videoModal, mainHall.centerVideo.url, 'horizontal')"></div>

    <div class="far-left-video hover" *ngIf="mainHall.farLeftVideo.url"
        (click)="openModal(videoModal, mainHall.farLeftVideo.url, 'vertical')"></div>

    <div class="far-right-video hover" *ngIf="mainHall.farRightVideo.url"
        (click)="openModal(videoModal, mainHall.farRightVideo.url, 'vertical')"></div>

    <div class="close-left-video hover" *ngIf="mainHall.closeLeftVideo.url"
        (click)="openModal(videoModal, mainHall.closeLeftVideo.url, 'vertical')"></div>

    <div class="close-right-video hover" *ngIf="mainHall.closeRightVideo.url"
        (click)="openModal(videoModal, mainHall.closeRightVideo.url, 'vertical')"></div>

    <div class="intro-video hover" *ngIf="mainHall.introVideo.url" tooltip="Click For Video" triggers=""
        #pop1="bs-tooltip" (click)="openModal(videoModal, mainHall.introVideo.url, 'horizontal')">
        <span class="tooltiptext  font-weight-bold">Click For Video</span>
    </div>


    <!-- <div *ngFor="let room of rooms | slice:0:5; let i = index" [id]="'room' + i" (click)="redirectToSession(room.guid)">
  </div> -->

    <div class="auditrium hover text-center" *ngIf="+countDown.seconds >= 0">
        <span class="tooltiptext">{{'Opens in ' + (+countDown.days * 24 + +countDown.hours) + 'h' + +countDown.minutes +
      'm'}}</span>
    </div>

    <div class="auditrium hover text-center" (click)="redirectToSession(rooms[0].guid)" tooltip="Live Now" triggers=""
        #pop2="bs-tooltip" *ngIf="+countDown.seconds < 0">
        <span class="tooltiptext bounce  text-uppercase font-weight-bold">Live Now</span>
    </div>

    <div class="auditrium hover text-center" tooltip="Event Ended" *ngIf="eventEnded" triggers="">
        <span class="tooltiptext">Event Ended</span>
    </div>

    <div class="booths hover text-center" *ngIf="+countDown.days >= 0 && +countDown.hours >= 1 && activateBooths"
        triggers="">
        <span class="tooltiptext">{{ 'Opens in ' + (+countDown.days * 24 + +countDown.hours - 1) + 'h' + +countDown.minutes
      + 'm' }}</span>
    </div>


    <div class="booths hover text-center" [routerLink]="['/booths/', eventID]" tooltip="Live Now"
        *ngIf="+countDown.days <= 0 && +countDown.hours <= 0 && activateBooths" triggers="" #pop3="bs-tooltip">
        <span class="tooltiptext bounce  text-uppercase font-weight-bold">Live Now</span>
    </div>

    <div class="booths hover text-center" tooltip="Event Ended" *ngIf="eventEnded && activateBooths" triggers="">
        <span class="tooltiptext">Event Ended</span>
    </div>

    <!-- <div class="booths hover text-center" *ngIf="+meetingCountdown.seconds < 0" (click)="redirectToSession()">
    <span class="tooltiptext bounce font-weight-bold">Live Now</span>
  </div> -->

    <!-- <div class="booths hover text-center" tooltip="Event Ended" *ngIf="eventEnded" triggers="">
    <span class="tooltiptext">Event Ended</span>
  </div> -->

    <!-- <div class="booths hover text-center" *ngIf="+meetingCountdown.seconds >= 0">
    <span
      class="tooltiptext">{{'Opens in ' + (+meetingCountdown.days * 24 + +meetingCountdown.hours) + 'h' + +meetingCountdown.minutes + 'm'}}</span>
  </div> -->

    <!-- <div class="arrow bounce"> -->
    <!-- <div class="arrow"> -->
    <!-- <h3>Need Help?/h3> -->
    <!-- <fa-icon [icon]="faAngleDown"></fa-icon> -->
    <!-- </div> -->

    <!-- Support -->

    <!-- Remove for Infinity -->
    <!-- <div class="support hover" [routerLink]="['/support', eventID]">
    <span class="tooltiptext bounce  text-uppercase font-weight-bold">
      Enter Here
      Need Help?
    </span>
  </div> -->

    <div class="countown hover" id="step3">
        <div class="row text-center"
            [ngStyle]="{'color': this.mainHall.secondaryColor, 'background-color': 'transparent'}"
            *ngIf="!eventStarted && !eventEnded">
            <h3 class="w-100 mt-0">Event Starts in:</h3>
            <div class="offset-2 col-2 p-0 text-center">
                <h2>{{ countDown.days }}</h2>
            </div>
            <div class="col-2 p-0 text-center">
                <h2>{{ countDown.hours }}</h2>
            </div>
            <div class="col-2 p-0 text-center">
                <h2>{{ countDown.minutes }}</h2>
            </div>
            <div class="col-2 p-0 text-center">
                <h2>{{ countDown.seconds }}</h2>
            </div>
            <div class="offset-2 col-2 p-0 text-center">
                <h6>Days</h6>
            </div>
            <div class="col-2 p-0 text-center">
                <h6>Hours</h6>
            </div>
            <div class="col-2 p-0 text-center">
                <h6>Minutes</h6>
            </div>
            <div class="col-2 p-0 text-center">
                <h6>Seconds</h6>
            </div>
        </div>

        <div class="row text-center"
            [ngStyle]="{'color': this.mainHall.secondaryColor, 'background-color': 'transparent'}"
            *ngIf="eventStarted && !eventEnded && activateBooths">
            <h3 class="w-100 font-weight-bold" style="padding: 1vw;">Event Started. please click on Auditorium for live
                stream.</h3>
        </div>

        <div class="row text-center"
            [ngStyle]="{'color': this.mainHall.secondaryColor, 'background-color': 'transparent'}"
            *ngIf="eventStarted && !eventEnded && !activateBooths">
            <h3 class="w-100 font-weight-bold" style="padding: 1vw;">Event Started. please click on Auditorium for live
                stream.</h3>
        </div>

        <div class="row text-center"
            [ngStyle]="{'color': this.mainHall.secondaryColor, 'background-color': 'transparent'}" *ngIf="eventEnded">
            <h3 class="w-100 font-weight-bold" style="padding: 1vw;">Event Ended</h3>
        </div>

    </div>

    <div class="left-clock text-center" [ngStyle]="{'color': this.mainHall.secondaryColor}">
        <h1 class="font-weight-bold"><span *ngIf="activeTemplate == 3">Local Time</span><br *ngIf="activeTemplate == 3">{{
      time | date: 'hh:mm' }}</h1>
    </div>

    <div class="right-clock text-center" [ngStyle]="{'color': this.mainHall.secondaryColor}">
        <h1 class="font-weight-bold"><span *ngIf="activeTemplate == 3">GMT Time</span><br *ngIf="activeTemplate == 3">{{
      timeGMT | date: 'hh:mm' }}</h1>
    </div>

    <!-- (click)="openFeedback(feedBack)" -->
    <div class="feedback hover" [routerLink]="['/agenda', eventID]" id="step4" tooltip="Click For Agenda" triggers=""
        #pop4="bs-tooltip">
        <span class="tooltiptext  font-weight-bold">Click For Agenda</span>
    </div>

</div>

<ng-template #videoModal>
    <div class="modal-header border-0">
        <!-- <h4 class="modal-title pull-left">Modal Header</h4> -->
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <iframe [src]="activeVideo | vimeoUrl" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen"
            allowfullscreen></iframe>
    </div>
</ng-template>


<ng-template #feedBack>
    <div class="modal-header border-0">
        <h4 class="modal-title pull-left text-white">Event Agenda</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- Dummy Agenda -->

        <div class="row header-row text-center text-uppercase pb-4 pt-4"
            [ngStyle]="{'color': this.mainHall.primaryColor}">
            <div class="col-3">
                <h5 class="font-weight-bold">Speaker</h5>
            </div>
            <div class="col-md-7 col-6">
                <h5 class="font-weight-bold">Topic</h5>
            </div>
            <div class="col-md-2 col-3">
                <h5 class="font-weight-bold">Time</h5>
            </div>
            <!-- <div class="col-4">
        <h5 class="font-weight-bold">Topic</h5>
      </div> -->
        </div>


        <div class="row text-center text-white record-row pb-2 pt-2" *ngFor="let record of detailedAgenda">
            <div class="col-3">
                <h6 *ngIf="record.speakers_name">{{record.speakers_name}}</h6>
            </div>
            <div class="col-md-7 col-6">
                <h6 *ngIf="record.subject">{{record.subject}}</h6>
            </div>
            <div class="col-md-2 col-3">
                <h6 *ngIf="record.time_start">{{record.time_start}} - {{ record.time_end }}</h6>
            </div>
            <!-- <div class="col-4">
        <h6>{{record.name}}</h6>
      </div> -->
        </div>

        <!-- <iframe src="https://tawk.to/chat/5edfae4b4a7c6258179a4179/1eai1914a" frameborder="0" width="100%" height="100%"></iframe> -->


    </div>
</ng-template>



<ng-template #tutorialModal>
    <div class="modal-header border-0">
        <h2 class="modal-title pull-left desktop-only">Welcome to {{title}}</h2>
        <button type="button" class="close pull-right text-dark" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <swiper [config]="config" #usefulSwiper>
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let slide of slideData; let i = index">
                    <div class="row">
                        <div class="col-12 col-lg-4 helper-div">
                            <span class="helper"></span>
                            <div class="vertical-middle">
                                <h3 class="font-weight-bold">( {{ i + 1 }} / {{ slideData.length }} )</h3>
                                <h2>{{ slide.name }}</h2>
                                <p class="font-smaller">{{slide.desc}}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-8">
                            <img [src]="slide.url" class="w-100" />
                        </div>
                    </div>
                </div>

            </div>

            <!-- Add Pagination -->
            <!-- <div class="swiper-pagination"></div> -->

            <!-- Add Arrows -->
            <!-- <div class="swiper-button-next"></div> -->
            <!-- <div class="swiper-button-prev"></div> -->

            <!-- Add Buttons -->
            <div class="row mt-5">
                <div class="mx-auto text-center">
                    <button class="btn btn-primary mr-4 border-0 text-wihte font-weight-bold" aria-label="Close"
                        (click)="modalRef.hide()" [ngStyle]="{'background-color': 'black'}"
                        style="width: 200px; box-shadow: none;" *ngIf="counter != slideData.length - 1">SKIP
                        Tutorial</button>

                    <button class="btn btn-primary mr-4 border-0 text-wihte font-weight-bold" aria-label="Close"
                        (click)="modalRef.hide()" [ngStyle]="{'background-color': 'black'}"
                        style="width: 200px; box-shadow: none;" *ngIf="counter == slideData.length - 1">Continue to
                        Event</button>

                    <button class="btn btn-primary mr-2 border-0 font-weight-bold"
                        (click)="usefulSwiper.swiper.slidePrev(); counter = counter - 1"
                        [ngStyle]="{'background-color': this.mainHall.primaryColor}"
                        style="width: 150px; box-shadow: none;" [disabled]="counter == 0">PREV</button>

                    <button class="btn btn-primary ml-2 border-0 font-weight-bold "
                        (click)="usefulSwiper.swiper.slideNext();  counter = counter + 1"
                        [ngStyle]="{'background-color': this.mainHall.primaryColor}"
                        style="width: 150px; box-shadow: none;"
                        [disabled]="counter == slideData.length - 1">NEXT</button>
                </div>
            </div>

        </swiper>
    </div>
</ng-template>


<ng-template #surveyModal>
    <div class="modal-header border-0">
        <!-- <h2 class="modal-title pull-left desktop-only">Welcome to {{title}}</h2> -->
        <button type="button" class="close pull-right text-white" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdsB0ahdMzxEbakBo2QiWNF0uA5P5nnsViuYyi-jzsMos4PMw/viewform?embedded=true"
            width="100%" height="1485" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
</ng-template>

<ng-template #BayerAPIModal>
    <div class="modal-header border-0">
        <!-- <h2 class="modal-title pull-left desktop-only">Welcome to {{title}}</h2> -->
        <button type="button" class="close pull-right text-white" aria-label="Close" (click)="modalRef.hide()">
            <!-- <span aria-hidden="true">&times;</span> -->
            <fa-icon [icon]="faTimesCircle" class="text-white"></fa-icon>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-white">API: </p>

        <p class="text-white"><b>Jivi ® - Composition</b>: Powder with 500/1000/2000 IE of Damoctocog alfa Pegol
            (pegylated
            B-domain-deleted recombinant human coagulation factor VIII) per injection vial. <b>Indications</b>: Therapy
            and
            prophylaxis of bleeding in previously treated patients (PTPs) ≥12 years with hemophilia A (congenital factor
            VIII
            deficiency). Not indicated in case of von Willebrand disease. <br> <b>Dosage & Administration</b>: The
            posology
            and duration of factor VIII substitution are adjusted to the individual needs of the patient (depending on
            patient
            weight, degree of factor VIII deficiency, location and extent of bleeding and desired factor VIII plasma
            level).
            Recommendation for initial prophylaxis 30-40 IU/kg twice weekly Based on the patient‘s frequency of
            bleeding,
            45-60 IU/kg can be applied every 5 days. It can be further adjusted to less frequent doses to suit
            individual
            requirements, e.g. 60 IU/kg every 7 days. <br> <b>Preparation</b>: Mixing with other medicinal products &
            injection
            solutions is not permitted. <b>Contra-indications</b>: Hypersensitivity to the active substance or to one of
            the
            excipients. Known allergy to mouse or hamster proteins & It is not indicated for use in children under
            12 years of
            age due to an increased risk of hypersensitivity reactions. - <b>Warnings</b>: Hypersensitivity reactions
            (including
            anaphylaxis). Formation of factor VIII inhibitors. Immune response to polyethylene glycol (PEG): in the
            first 4
            days of exposure with symptoms of acute hypersensitivity and/or loss of clinical efficacy (temporary,
            associated
            with anti-PEG antibodies, absence of factor VIII inhibitors). Low factor VIII level following the injection
            without detectable factor VIII inhibitors may indicate anti-PEG antibodies; Jivi ® must no longer be taken.
            Catheter-related infections. Risk of contracting cardiovascular diseases. Pregnancy and lactation. -
            <b>Interactions</b>: no interactions with other medicinal products have been reported. <b>Adverse effects:
                Very
                common: </b>Headache, <b>Common</b>: Hypersensitivity reactions, insomnia, vertigo, abdominal pain,
            nausea,
            vomiting, erythema, rash, localized reactions at the injection site, fever, cough - <b>Reporting of side
                effects:
                If you get any side effects, talk to your doctor or pharmacist. This includes any possible side effects
                not
                listed in this leaflet. By reporting side affects you can help provide more information on the safety of
                this
                medicine.</b>
        </p>

        <p class="text-white">
            - Please refer to the Product Package Insert Leaflet (PIL) for full details of usage and dosage.
        </p>

        <p class="text-white"><b>Manufactured by:</b> Bayer HealthCare LLC, Berkeley, USA <br> <b>Final release by:</b>
            Bayer AG - 51368 Leverkusen, Germany. <br> <b>ME PIL version: Nov. 2018</b></p>

    </div>
</ng-template>







<!-- Mobile Version -->
<div [class]="'container-fluid p-0 m-0 mobile-main-hall mobile-only position-relative mobileTemplate' + activeTemplate"
    [ngStyle]="{ 'background-image': 'url(' + mainHall.mobileBackgroundImage.src + ')'}" *ngIf="!loading">

    <!-- Event Name -->
    <!-- <div class="mobile-name text-center" *ngIf="activeTemplate == 2">
    <img [src]="mainHall.mobileName.src" [alt]="title" class="w-75 mt-5">
  </div> -->

    <!-- Countdown  -->
    <div class="mobile-countdown">
        <div class="row text-center"
            [ngStyle]="{'color': this.mainHall.secondaryColor, 'background-color': 'transparent'}"
            *ngIf="!eventStarted && !eventEnded">
            <h3 class="w-100">Event Starts in:</h3>
            <div class="offset-2 col-2 p-0 text-center">
                <h2>{{ countDown.days }}</h2>
            </div>
            <div class="col-2 p-0 text-center">
                <h2>{{ countDown.hours }}</h2>
            </div>
            <div class="col-2 p-0 text-center">
                <h2>{{ countDown.minutes }}</h2>
            </div>
            <div class="col-2 p-0 text-center">
                <h2>{{ countDown.seconds }}</h2>
            </div>
            <div class="offset-2 col-2 p-0 text-center">
                <h6>D</h6>
            </div>
            <div class="col-2 p-0 text-center">
                <h6>H</h6>
            </div>
            <div class="col-2 p-0 text-center">
                <h6>M</h6>
            </div>
            <div class="col-2 p-0 text-center">
                <h6>S</h6>
            </div>
        </div>

        <div class="row text-center"
            [ngStyle]="{'color': this.mainHall.secondaryColor, 'background-color': 'transparent'}"
            *ngIf="eventStarted && !eventEnded && activateBooths">
            <h3 class="w-100 font-weight-bold" style="padding: 1vw;">Event Started. please click on Auditorium for live
                stream.</h3>
        </div>

        <div class="row text-center"
            [ngStyle]="{'color': this.mainHall.secondaryColor, 'background-color': 'transparent'}"
            *ngIf="eventStarted && !eventEnded && !activateBooths">
            <h3 class="w-100 font-weight-bold" style="padding: 1vw;">Event Started. please click on Auditorium for live
                stream.</h3>
        </div>

        <div class="row text-center"
            [ngStyle]="{'color': this.mainHall.secondaryColor, 'background-color': 'transparent'}" *ngIf="eventEnded">
            <h3 class="w-100 font-weight-bold" style="padding: 1vw;">Event Ended</h3>
        </div>
    </div>


    <!-- Looping Video -->
    <div class="mobile-middle-video">
        <iframe [src]="mainHall.topMiddleVideo.url + '?muted=1&loop=1&autoplay=1' | vimeoUrl" width="100%" height="100%"
            frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>

    <!-- Intro Video -->
    <div class="mobile-intro-video" (click)="openModal(videoModal, mainHall.introVideo.url, 'horizontal')"
        *ngIf="mainHall.introVideo.url">
        <img [src]="mainHall.mobileIntroPic.src" *ngIf="activeTemplate == 2" class="w-100">
    </div>

    <!-- Agenda -->
    <div class="mobile-agenda" [routerLink]="['/agenda', eventID]">
        <img [src]="mainHall.mobileAgendaImg.src" *ngIf="activeTemplate == 2" class="w-100">
    </div>

    <!-- reception -->
    <!-- (click)="openSupportChat()"  -->
    <!-- <div class="mobile-reception" *ngIf="activeTemplate != 3">
    <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a>
  </div> -->

    <!-- reception -->
    <div class="mobile-reception" [routerLink]="['/support', eventID]" *ngIf="activeTemplate == 3">
        <span class="tooltiptext bounce  text-uppercase font-weight-bold">
            <!-- Enter Here -->
            Need Help?
        </span>
    </div>

    <!-- Auditorium -->
    <!-- [routerLink]="['/plenary-session', eventID]" -->
    <div class="mobile-auditorium" *ngIf="+countDown.seconds < 0" tooltip="Live Now"
        (click)="redirectToSession(rooms[0].guid)">
        <img [src]="mainHall.mobileAuditoriumImg.src" *ngIf="activeTemplate == 2" class="w-100">
    </div>

    <div class="mobile-auditorium" tooltip="Event Ended" *ngIf="eventEnded">
        <img [src]="mainHall.mobileAuditoriumImg.src" *ngIf="activeTemplate == 2" class="w-100">
    </div>

    <div class="mobile-auditorium" *ngIf="+countDown.seconds >= 0"
        [tooltip]="'Opens in ' + (+countDown.days * 24 + +countDown.hours) + 'h' + +countDown.minutes + 'm'">
        <img [src]="mainHall.mobileAuditoriumImg.src" *ngIf="activeTemplate == 2" class="w-100">
    </div>

    <!-- Booths -->
    <div class="mobile-booth" *ngIf="+countDown.days >= 0 && +countDown.hours >= 1 && activateBooths"
        [tooltip]="'Opens in ' + (+countDown.days * 24 + +countDown.hours) + 'h' + +countDown.minutes +'m'">
        <img [src]="mainHall.mobileBoothImg.src" *ngIf="activeTemplate == 2" class="w-100">
    </div>

    <div class="mobile-booth" [routerLink]="['/booths/', eventID]" tooltip="Live Now"
        *ngIf="+countDown.days <= 0 && +countDown.hours <= 0 && activateBooths && numberOfBooths > 1">
        <img [src]="mainHall.mobileBoothImg.src" *ngIf="activeTemplate == 2" class="w-100">
    </div>

    <div class="mobile-booth" [routerLink]="['/booth/', eventID, 'booth1']" tooltip="Live Now"
        *ngIf="+countDown.days <= 0 && +countDown.hours <= 0 && activateBooths && numberOfBooths == 1">
        <img [src]="mainHall.mobileBoothImg.src" *ngIf="activeTemplate == 2" class="w-100">
    </div>

    <div class="mobile-booth" tooltip="Event Ended" *ngIf="eventEnded && activateBooths">
        <img [src]="mainHall.mobileBoothImg.src" *ngIf="activeTemplate == 2" class="w-100">
    </div>

    <div class="left-clock text-center" [ngStyle]="{'color': this.mainHall.secondaryColor}">
        <h1 class="font-weight-bold"><span *ngIf="activeTemplate == 3">Local Time</span><br
                *ngIf="activeTemplate == 3"><span *ngIf="activeTemplate == 2">Local</span><br
                *ngIf="activeTemplate == 2">{{
      time | date: 'hh:mm' }}</h1>
    </div>

    <div class="right-clock text-center" [ngStyle]="{'color': this.mainHall.secondaryColor}">
        <h1 class="font-weight-bold"><span *ngIf="activeTemplate == 3">GMT Time</span><br
                *ngIf="activeTemplate == 3"><span *ngIf="activeTemplate == 2">GMT</span><br *ngIf="activeTemplate == 2">{{
      timeGMT | date: 'hh:mm' }}</h1>
    </div>

    <!-- <div class="mobile-gallery" [routerLink]="['/gallery/', eventID]" tooltip="Live Now"
    *ngIf="activeTemplate == 3">
    <img [src]="mainHall.mobileGalleryImg.src" class="w-100">
  </div> -->

    <!-- *ngIf="+countDown.seconds < 0" (click)="redirectToSession()" -->

    <!-- <div class="mobile-booth" *ngIf="+countDown.seconds < 0" (click)="redirectToSession()">
    <img [src]="mainHall.mobileBoothImg.src" alt="Booths" class="w-100">
  </div> -->


</div>
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventService } from '../services/event.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TimerService } from '../services/timer.service';

@Component({
  selector: 'app-booth',
  templateUrl: './booth.component.html',
  styleUrls: ['./booth.component.scss']
})
export class BoothComponent implements OnInit, OnDestroy {
  // boothsPics = [
  //   { id: 1, name: "Booth1", background: 'assets/images/booth1.jpg', type: 'game', chatLink: 'https://tawk.to/chat/5edfae4b4a7c6258179a4179/1eb1juq2h', },
  //   { id: 2, name: "Booth2", background: 'assets/images/booth2.jpg', type: 'video', chatLink: 'https://tawk.to/chat/5edfae4b4a7c6258179a4179/1eb1k791d', },
  //   { id: 3, name: "Booth3", background: 'assets/images/booth3.jpg', type: 'game', chatLink: 'https://tawk.to/chat/5edfae4b4a7c6258179a4179/1eb1ka0ds', },
  // ];
  booths: any;
  eventID: string;
  boothID: number;
  currentBooth: any = { materials: [] };
  loading: boolean = true;
  backgroundImage = new Image();
  mobileBackgroundImage = new Image();
  numberOfBooths: number;
  modalRef: BsModalRef;
  activeLink: string;
  templateNumber: number;
  @ViewChild('externalLinkModal', { static: false }) externalLinkModal: TemplateRef<any>;
  queryParams: string = '?username=' + String(localStorage.getItem('firstName')) + '+' + String(localStorage.getItem('lastName')) + '&email=' + String(localStorage.getItem('email'))

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private router: Router,
    private modalService: BsModalService,
    private timerService: TimerService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.eventID = params.eventID;
      this.boothID = params.boothID;
      if (this.eventService.currentEvent) {

        this.numberOfBooths = this.eventService.currentEvent.booths.length;
        console.log(this.numberOfBooths);

        this.templateNumber = this.eventService.currentEvent.events.template;
        console.log(this.templateNumber);

        this.booths = this.eventService.currentEvent.booths;
        // console.log(this.eventService.currentEvent.booths);
        if (this.eventService.currentEvent.booths.length) {
          this.currentBooth = this.booths.find(booth => booth.name.toLowerCase() == this.boothID);
          console.log(this.currentBooth);

          if (this.currentBooth && this.currentBooth?.materials) {
            this.currentBooth.downloadLinks = [];
            console.log(this.currentBooth.materials);

            this.currentBooth.materials.forEach(material => {
              this.currentBooth.downloadLinks.push("assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/' + material);
            });

            console.log(this.currentBooth?.downloadLinks);

          }
          // this.currentBooth.downloadLink = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/' + String(this.currentBooth.subject) + '.pdf';

          this.timerService.sendActivity(this.currentBooth.rooms);

        } else {
          this.currentBooth = {
            background: ''
          }
        }
        // this.currentBooth.background = this.boothsPics.find(booth => booth.name.toLowerCase() == String(this.boothID)).background;
        // this.currentBooth.background = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/' + String(this.boothID) + '.jpg';
        this.backgroundImage.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/' + String(this.boothID) + '.jpg';
        this.mobileBackgroundImage.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/' + "mobile-" + String(this.boothID) + '.jpg';
        // console.log(this.currentBooth);
        setTimeout(() => {
          this.loading = false;
        }, 1000)
      } else {
        this.router.navigate(['/event', this.eventID]);
      }
    })

  }

  openChat(externalChatLink) {
    // window.open(externalLink, 'name', 'width=500,height=500');

    this.eventService.openChat.next({
      name: this.currentBooth.name,
      url: externalChatLink
    })

  }

  openModal(chatModal: TemplateRef<any>, link) {
    // console.log(link);
    this.activeLink = link;

    let config = {
      ignoreBackdropClick: true,
      class: 'bg-modal'
    };

    this.modalRef = this.modalService.show(chatModal, config);
    // this.eventService.openPublicChat.next(true);
    // console.log(link);
  }

  ngOnDestroy() {
    this.timerService.sendEndActivity(this.timerService.endTimer());
  }

}

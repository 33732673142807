<div class="my-container">
  <ng-template #customLoadingTemplate> </ng-template>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }"
    [template]="customLoadingTemplate"
  ></ngx-loading>
</div>

<app-header [eventID]="eventID"></app-header>

<div
  [class]="'container-fluid background desktop-only template' + activeTemplate"
  [ngStyle]="{ 'background-image': 'url(' + backgroundImage.src + ')' }"
>
  <div class="liveBanner" *ngIf="meeting.ExternalLinks">
    <iframe
      [src]="meeting.ExternalLinks + '?muted=1&loop=1&autoplay=1' | vimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>

  <div class="liveVideo">
    <iframe
      *ngIf="windowWidth > 650"
      [src]="meeting.liveVideoURL + '?loop=1&autoplay=1' | liveVimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>

  <div class="liveChat bg-white" *ngIf="!meeting.chatURL">
    <div class="h-100 w-100 helper-div">
      <span class="helper"></span>
      <h4 class="vertical-center pl-2 pr-2">
        Chat isn't Available now for this meeting
      </h4>
    </div>
  </div>

  <div class="liveChat bg-white">
    <iframe
      *ngIf="windowWidth > 1200"
      #chat1
      [src]="meeting.chatURL | liveVimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
    <div class="btn-overlay">
      <button (click)="openChat()">Open Q&A</button>
    </div>
  </div>

  <div class="right-clock">
    <h1>
      <span *ngIf="activeTemplate == 3">Local Time</span
      ><br *ngIf="activeTemplate == 3" />
      {{ time | date : "hh:mm" }}
    </h1>
  </div>

  <div class="left-clock">
    <h1>
      <span *ngIf="activeTemplate == 3">GMT Time</span
      ><br *ngIf="activeTemplate == 3" />
      {{ timeGMT | date : "hh:mm" }}
    </h1>
  </div>
</div>

<!-- <app-footer></app-footer> -->

<!-- Mobile Version -->
<div
  [class]="
    'container-fluid mobile-background mobile-only position-relative mobileTemplate' +
    activeTemplate
  "
  [ngStyle]="{ 'background-image': 'url(' + mobileBackgroundImage.src + ')' }"
>
  <div class="mobile-right-clock">
    <h1>{{ time | date : "hh:mm" }}</h1>
  </div>

  <div class="mobile-left-clock">
    <h1>
      {{ timeGMT | date : "hh:mm" }}
    </h1>
  </div>

  <div class="mobile-liveVideo">
    <iframe
      *ngIf="windowWidth <= 650"
      [src]="meeting.liveVideoURL + '?loop=1&autoplay=1' | liveVimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>

  <div class="mobile-liveChat">
    <iframe
      *ngIf="windowWidth < 650"
      #chat2
      [src]="meeting.chatURL | liveVimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </div>
</div>

<section
  (click)="closeChat()"
  [ngClass]="{ 'chat-overlay': true, visible: chatLayerVisability }"
>
  <div class="btn-container">
    <button (click)="closeChat()">×</button>
  </div>
  <iframe
    *ngIf="windowWidth > 650 && windowWidth < 1200"
    #chat3
    [src]="meeting.chatURL | liveVimeoUrl"
    frameborder="0"
  ></iframe>
</section>

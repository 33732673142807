<div class="my-container">
  <ng-template #customLoadingTemplate>
  </ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop:true }"
    [template]="customLoadingTemplate"></ngx-loading>
</div>


<app-tawk-chat [id]="supportChatID"></app-tawk-chat>

<app-header [eventID]="eventID"></app-header>

<div [class]="'container-fluid background desktop-only template' + activeTemplateID"
  [ngStyle]="{ 'background-image': 'url(' + backgroundImage.src + ')'}" *ngIf="activeTemplateID && countDown">

  <div class="chat">
    <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a>
    <!-- <a (click)="openChat(supportChatID)" class="d-block h-100"></a> -->
    <span class="tooltiptext bounce  text-uppercase font-weight-bold">Need Help?</span>
  </div>


  <div class="left-clock text-center" [ngStyle]="{'color': secondaryColor}">
    <h1 class="font-weight-bold"><span>Local Time</span><br>{{ time | date: 'hh:mm' }}</h1>
  </div>

  <div class="right-clock text-center" [ngStyle]="{'color': secondaryColor}">
    <h1 class="font-weight-bold"><span>GMT Time</span><br>{{ timeGMT | date: 'hh:mm' }}</h1>
  </div>


  <div class="auditorium hover text-center" *ngIf="+countDown.seconds >= 0">
    <span class="tooltiptext">{{'Opens in ' + (+countDown.days * 24 + +countDown.hours) + 'h' + +countDown.minutes +
      'm'}}</span>
  </div>

  <div class="auditorium hover text-center" (click)="redirectToSession(rooms[0].guid)" *ngIf="+countDown.seconds < 0">
    <span class="tooltiptext bounce  text-uppercase font-weight-bold">Live Now</span>
  </div>

  <div class="auditorium hover text-center" tooltip="Event Ended" *ngIf="eventEnded">
    <span class="tooltiptext">Event Ended</span>
  </div>

  <div class="booths hover text-center" *ngIf="+countDown.days >= 0 && +countDown.hours >= 1 && activateBooths">
    <span class="tooltiptext">{{ 'Opens in ' + (+countDown.days * 24 + +countDown.hours - 1) + 'h' + +countDown.minutes
      + 'm' }}</span>
  </div>


  <div class="booths hover text-center" [routerLink]="['/booths/', eventID]"
    *ngIf="+countDown.days <= 0 && +countDown.hours <= 0 && activateBooths">
    <span class="tooltiptext bounce  text-uppercase font-weight-bold">Live Now</span>
  </div>

  <div class="booths hover text-center" tooltip="Event Ended" *ngIf="eventEnded && activateBooths">
    <span class="tooltiptext">Event Ended</span>
  </div>

</div>


<div [class]="'container-fluid mobile-main-hall mobile-only mobileTemplate' + activeTemplateID"
  [ngStyle]="{ 'background-image': 'url(' + mobileBackgroundImage.src + ')'}" *ngIf="activeTemplateID && countDown ">

  <!-- reception -->
  <div class="mobile-reception" (click)="openChat(supportChatID)">
    <a href="javascript:void(Tawk_API.toggle())" class="d-block h-100"></a>
  </div>

  <!-- Auditorium -->
  <!-- [routerLink]="['/plenary-session', eventID]" -->
  <div class="mobile-auditorium" *ngIf="+countDown.seconds < 0" tooltip="Live Now"
    (click)="redirectToSession(rooms[0].guid)">
    <img [src]="mainHall.mobileAuditoriumImg.src" class="w-100">
  </div>

  <div class="mobile-auditorium" tooltip="Event Ended" *ngIf="eventEnded">
    <img [src]="mainHall.mobileAuditoriumImg.src" class="w-100">
  </div>

  <div class="mobile-auditorium" *ngIf="+countDown.seconds >= 0"
    [tooltip]="'Opens in ' + (+countDown.days * 24 + +countDown.hours) + 'h' + +countDown.minutes + 'm'">
    <img [src]="mainHall.mobileAuditoriumImg.src" class="w-100">
  </div>

  <!-- Booths -->
  <div class="mobile-booth" *ngIf="+countDown.days >= 0 && +countDown.hours >= 1 && activateBooths"
    [tooltip]="'Opens in ' + (+countDown.days * 24 + +countDown.hours) + 'h' + +countDown.minutes +'m'">
    <img [src]="mainHall.mobileBoothImg.src" class="w-100">
  </div>

  <div class="mobile-booth" [routerLink]="['/booths/', eventID]" tooltip="Live Now"
    *ngIf="+countDown.days <= 0 && +countDown.hours <= 0 && activateBooths && numberOfBooths > 1">
    <img [src]="mainHall.mobileBoothImg.src" class="w-100">
  </div>

  <div class="mobile-booth" [routerLink]="['/booth/', eventID, 'booth1']" tooltip="Live Now"
    *ngIf="+countDown.days <= 0 && +countDown.hours <= 0 && activateBooths && numberOfBooths == 1">
    <img [src]="mainHall.mobileBoothImg.src" class="w-100">
  </div>

  <div class="mobile-booth" tooltip="Event Ended" *ngIf="eventEnded && activateBooths">
    <img [src]="mainHall.mobileBoothImg.src" class="w-100">
  </div>

  <div class="mobile-gallery" [routerLink]="['/gallery/', eventID]" tooltip="Live Now" *ngIf="activeTemplateID == 3">
    <img [src]="mainHall.mobileGalleryImg.src" class="w-100">
  </div>

</div>

<!-- <app-footer></app-footer> -->
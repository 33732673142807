import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  faHome,
  faUser,
  faArrowLeft,
  faCompress,
  faExpand,
  faClone,
  faLaptopHouse,
  faVideo,
  faCalendarAlt,
  faDownload,
  faArchway,
  faInfo,
  faImage,
  faPollH,
} from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { EventService } from '../services/event.service';
import { ToastrService } from 'ngx-toastr';
import { TimerService } from '../services/timer.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export declare function openFullscreen();

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  faHome = faHome;
  faUser = faUser;
  faArrowLeft = faArrowLeft;
  @Input() eventID: string;
  name: string;
  profilePic: string;
  activateBooths: boolean;
  downloadLinks: any = [];
  numberOfBooths: number;
  fullscreen: boolean = this.eventService.isFullscreen();
  countDown: any;
  rooms: any;
  enableMainHall: boolean;
  activateGallery: boolean;

  allData: any;

  faClenderAlt = faCalendarAlt;
  faVideo = faVideo;
  faLaptopHouse = faLaptopHouse;
  faClone = faClone;
  faExpand = faExpand;
  faCompress = faCompress;
  faDownload = faDownload;
  faArchway = faArchway;
  faInfo = faInfo;
  faImage = faImage;

  menuItemsArray: any[] = [];

  menuConfig: any = {
    animation: 'collapse',
    offset: { top: 30 },
    closeOnCLick: true,
  };

  modalRef: BsModalRef;

  faPollH = faPollH;
  faTimesCircle = faTimesCircle;

  @ViewChild('BayerAPIModal') BayerAPIModal: TemplateRef<any>;

  constructor(
    private _location: Location,
    private authService: AuthService,
    private router: Router,
    private eventService: EventService,
    private toastr: ToastrService,
    private timerService: TimerService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.name =
      localStorage.getItem('firstName') +
      ' ' +
      localStorage.getItem('lastName');
    this.profilePic = localStorage.getItem('profile')
      ? localStorage.getItem('profile')
      : null;

    if (this.eventService.currentEvent) {
      // console.log(this.eventService.currentEvent);

      this.allData = { ...this.eventService.currentEvent };
      this.activateGallery =
        this.eventService.currentEvent.gallery &&
        this.eventService.currentEvent.gallery.length
          ? true
          : false;

      this.activateBooths = this.eventService.currentEvent.booths.length
        ? true
        : false;

      this.numberOfBooths = this.eventService.currentEvent.booths.length;

      // this.menuItemsArray.push({ title: this.eventService.currentEvent.rooms[0].name, link: '/event/' + this.eventID });

      this.rooms = this.eventService.currentEvent.rooms.filter(
        (room) => +room.type == 1
      );

      this.timerService
        .timer(
          new Date(this.eventService.currentEvent.events.startTimeinGMT * 1000)
        )
        .subscribe((respnseTime) => {
          this.countDown = respnseTime;
        });

      if (this.allData.support) {
        this.allData.support.forEach((room) => {
          this.menuItemsArray.push({
            title: room.name,
            link: '/' + 'support' + '/' + this.eventID,
          });
        });
      }

      this.rooms.forEach((room) => {
        this.menuItemsArray.push({
          title: room.name,
          link: '/' + room.name + '/' + this.eventID,
        });
      });

      if (this.activateBooths) {
        if (this.numberOfBooths == 1) {
          this.menuItemsArray.push({
            title: this.eventService.currentEvent.booths[0].rooms_name,
            link: '/booth/' + this.eventID + '/booth1',
          });
        } else {
          this.menuItemsArray.push({
            title: 'Booths',
            link: '/booths/' + this.eventID,
          });
        }
      }

      if (this.activateGallery) {
        this.menuItemsArray.push({
          title: this.eventService.currentEvent.gallery[0].rooms_name,
          link: '/gallery/' + this.eventID,
        });
      }

      this.menuItemsArray.push({
        title: 'Agenda',
        link: '/agenda/' + this.eventID,
      });

      // Only for Jivi
      // this.menuItemsArray.push({ title: 'API', link: '' });

      // this.menuItemsArray.push({ title: 'Go Fullscreen', link: '' });

      if (this.eventService.currentEvent.booths) {
        this.getDownloadMaterial(
          this.eventService.currentEvent.booths,
          this.eventService.currentEvent.events.name
        );
      }

      this.enableMainHall = this.eventService.currentEvent.rooms.find(
        (room) => +room.type == 3
      )
        ? true
        : false;
    }
  }

  backClicked() {
    this._location.back();
  }

  getDownloadMaterial(booths, name) {
    // console.log(booths);

    const mobileDownloads = [];

    booths.forEach((booth) => {
      // console.log(booth);

      if (!booth.materials.length) {
        booth.materials = [];
      }

      booth.materials.forEach((material) => {
        this.downloadLinks.push({
          name: material,
          url:
            'assets/' +
            name.trim().replace(/\s+/g, '-').toLowerCase() +
            '/' +
            material,
        });

        mobileDownloads.push({
          title: material,
          link:
            'assets/' +
            name.trim().replace(/\s+/g, '-').toLowerCase() +
            '/' +
            material,
        });
      });

      // this.downloadLinks.push({
      //   name: booth.subject,
      //   url: "assets/" + name.trim().replace(/\s+/g, '-').toLowerCase() + '/' + String(booth.subject) + '.pdf'
      // })
    });

    if (mobileDownloads.length) {
      this.menuItemsArray.push({
        title: 'Download Materials',
        link: '#',
        subItems: mobileDownloads,
      });
    }

    // console.log(this.downloadLinks);
  }

  onMenuClose() {
    console.log('menu closed');
  }
  onMenuOpen() {
    console.log('menu Opened');
  }
  onItemSelect(item: any) {
    // console.log(item);
    if (item.title == 'Go Fullscreen') {
      openFullscreen();
    } else if (item.title == 'Download Materials') {
    } else if (item.link.includes('_')) {
      console.log('download Links');
      window.open(item.link, '_blank');
    } else if (item.title == 'API') {
      this.openBayerModal(this.BayerAPIModal);
    } else {
      this.router.navigate([item.link]);
    }
  }

  signout() {
    this.authService.logout().subscribe(
      (response) => {
        this.eventService.showFooter.next(false);
        this.eventService.closePublicChat.next(true);
        this.router.navigate(['/login']);
      },
      (error) => {
        this.eventService.showFooter.next(false);
        this.eventService.closePublicChat.next(true);
        this.router.navigate(['/login']);
      }
    );
  }

  openFullScreen() {
    var elem: any = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }

    this.fullscreen = true;
  }

  closeFullScreen() {
    var elem: any = document;

    if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.mozCancelFullScreen) {
      elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) {
      elem.webkitExitFullscreen();
    } else if (elem.msExitFullscreen) {
      elem.msExitFullscreen();
    }

    this.fullscreen = false;
  }

  redirectToSession(guid) {
    // this.loading = true;

    this.eventService.redirectToSession(guid).subscribe(
      (response) => {
        console.log(response);
        const name = this.eventService.currentEvent.rooms.find(
          (room) => room.guid == response
        ).name;
        // this.loading = false;
        this.router.navigate([
          '/' + this.eventService.convertSpacesintoDashes(name).toLowerCase(),
          this.eventID,
        ]);
      },
      (error) => {
        console.log(error);
        // this.loading = false;
        this.toastr.error('', error.error, {
          closeButton: true,
          disableTimeOut: true,
        });
      }
    );
  }

  openBayerModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'bayerModal modal-dialog-centered' })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ChatweeService } from '../lobby-chat/services/chatwee.service';
import { CookieService } from 'ngx-cookie-service';
const shajs = require('sha.js');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgetPasswordForm: FormGroup;
  forget: boolean = false;
  forgetMessage: string;
  disabled: boolean = false;
  message: string;
  successMessage: string;
  fullName: string;
  showAlreadyLogged: boolean = false;
  eventID: string;
  registerIde: number;
  registerPwd: number;

  constructor(
    private authService: AuthService,
    private eventService: EventService,
    private router: Router,
    private titleService: Title,
    private chatweeService: ChatweeService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {

    this.titleService.setTitle('Login' + ' - Cloudvie');

    this.eventService.showFooter.next(false);

    this.loginForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl('123456', Validators.required),
    });

    this.forgetPasswordForm = new FormGroup({
      "email": new FormControl(null, [Validators.required, Validators.email])
    });

    this.authService.getRegsiterLink().subscribe((response: any) => {
      this.registerIde = response.ide;
      this.registerPwd = response.pwd;
    })


    // Check if user is already logged in
    if (localStorage.getItem('session')) {

      const timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
      const string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
      const token = shajs('sha256').update(string).digest('hex');

      const body = {
        "session_idu": localStorage.getItem('session'),
        "timestamp": timeNowinGMT,
        "token": token
      }

      this.authService.isLoggedIn(body).subscribe((response: any) => {
        // console.log(response);

        let is_member = response.is_member.slice(-2);
        if (is_member == '7b') {
          this.fullName = localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
          this.eventID = response.events;
          this.showAlreadyLogged = true;
          // this.eventService.getEvent().subscribe((response: any) => {
          // this.router.navigate(['/event', response.events]);
          // })
        } else {
          // this.successMessage = "You Don't have any upcoming Events";
        }

      })
    }

    // console.log(new Date(this.adjustForTimezone(new Date())).getTime() / 1000);
  }

  submitForm() {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.status === 'VALID') {

      this.disabled = true;

      const timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
      const string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
      const token = shajs('sha256').update(string).digest('hex');

      const body = {
        "email": this.loginForm.get('email').value,
        "password": this.loginForm.get('password').value,
        "timestamp": timeNowinGMT,
        "token": token
      }

      // console.log(body);

      this.authService.login(body).subscribe((response: any) => {
        // console.log(response);
        localStorage.setItem('session', response.session_idu);
        localStorage.setItem('firstName', response.fistName);
        localStorage.setItem('lastName', response.lastName);
        localStorage.setItem('profile', response.Profile);
        localStorage.setItem('email', this.loginForm.get('email').value);

        this.chatweeService.login(response.chatwee).subscribe((chatweeSession: any) => {
          // console.log('sessionis: ' + chatweeSession);
          localStorage.setItem('chatweeSession', chatweeSession);
          this.cookieService.deleteAll();
          this.cookieService.set(environment.SESSION_COOKIE_KEY, chatweeSession);
          // document.cookie = environment.SESSION_COOKIE_KEY + "=" + chatweeSession;

          let is_member = response.is_member.slice(-2);
          if (is_member == '7b') {
            this.successMessage = "Redirecting you to Your Event....";
            // this.eventService.getEvent().subscribe((response: any) => {
            // this.router.navigate(['/event', response.events]);
            location.assign("event/" + response.events);

            // })
          } else {
            this.successMessage = "You Don't have any upcoming Events";
          }
          this.disabled = false;

        }, error => {
          location.assign("event/" + response.events);
        })
      }, error => {
        // console.log(error);
        this.message = error.error;
        this.disabled = false;
      })

    }
  }

  submitForgetForm() {
    this.forgetPasswordForm.markAllAsTouched();

    if (this.forgetPasswordForm.status === 'VALID') {

      this.disabled = true;

      let timeNowinGMT = new Date(this.adjustForTimezone(new Date())).getTime() / 1000;
      let string = environment.merchantKey + ":" + timeNowinGMT + environment.API_KEY;
      let token = shajs('sha256').update(string).digest('hex');

      let body = {
        "email": this.forgetPasswordForm.get('email').value,
        "timestamp": timeNowinGMT,
        "token": token
      }

      this.authService.forgetPassword(body).subscribe((response: any) => {
        // console.log(response);
        this.forgetMessage = response.mesaj;
        this.disabled = false;
      }, error => {
        // console.log(error);
        this.forgetMessage = error.error;
        this.disabled = false;
      })

    }
  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date
  }

  goToEvent(id) {
    this.router.navigate(['/event', id]);
  }

}

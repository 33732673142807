import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventService } from '../services/event.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../services/auth.service';
import { TimerService } from '../services/timer.service';
@Component({
  selector: 'app-auditorium',
  templateUrl: './auditorium.component.html',
  styleUrls: ['./auditorium.component.scss'],
})
export class AuditoriumComponent implements OnInit, OnDestroy {
  windowWidth: number;
  @ViewChild('chat1') chat1: ElementRef;
  @ViewChild('chat2') chat2: ElementRef;
  @ViewChild('chat3') chat3: ElementRef;
  time = new Date();
  timeGMT: any;
  eventID: string;
  auditoriumID: string;
  currentMeeting: any;
  backgroundImage = new Image();
  mobileBackgroundImage = new Image();
  primaryColor: string;
  secondaryColor: string;
  activeTemplate: number;

  meeting = {
    liveVideoURL: '',
    chatURL: '',
    ExternalLinks: '',
  };

  loading: boolean = true;
  chatLayerVisability: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private titleService: Title,
    private timerService: TimerService,
    private cd: ChangeDetectorRef
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.windowWidth = document.body.clientWidth;
    // if (this.windowWidth != document.body.clientWidth) {
    //   this.windowWidth = document.body.clientWidth;
    //   const chat1 = this.chat1.nativeElement;
    //   const chat2 = this.chat2.nativeElement;
    //   const chat3 = this.chat3.nativeElement;
    //   chat1.src = chat1.src;
    //   chat2.src = chat2.src;
    //   chat3.src = chat3.src;
    //   this.cd.detectChanges();
    // }
  }

  ngOnInit(): void {
    this.windowWidth = document.body.clientWidth;
    this.route.params.subscribe((params: Params) => {
      this.eventID = params.eventID;
      this.auditoriumID = params.auditoriumID.toLowerCase();

      if (this.eventService.currentEvent) {
        console.log(this.eventService.currentEvent);
        this.titleService.setTitle(
          this.eventService.currentEvent.events.name +
            ' Plenary' +
            ' - Cloudvie'
        );
        this.currentMeeting = this.eventService.currentEvent.rooms.find(
          (room) =>
            room.name
              .toLowerCase()
              .includes(
                this.eventService.convertDashesintoSpaces(this.auditoriumID)
              )
        );
        this.backgroundImage.src =
          'assets/' +
          this.eventService.currentEvent.events.name
            .trim()
            .replace(/\s+/g, '-')
            .toLowerCase() +
          '/' +
          'Meeting-Room' +
          '.jpg';
        this.mobileBackgroundImage.src =
          'assets/' +
          this.eventService.currentEvent.events.name
            .trim()
            .replace(/\s+/g, '-')
            .toLowerCase() +
          '/mobile-' +
          'Meeting-Room' +
          '.jpg';
        this.meeting.liveVideoURL = this.currentMeeting.stream;
        this.meeting.chatURL = this.currentMeeting.supportChatID;
        this.meeting.ExternalLinks = this.currentMeeting.ExternalLinks;
        this.primaryColor = this.eventService.currentEvent.events.primaryColor;
        this.secondaryColor =
          this.eventService.currentEvent.events.secondaryColor;
        this.activeTemplate = this.eventService.currentEvent.events.template
          ? this.eventService.currentEvent.events.template
          : 1;

        this.timerService.sendActivity(this.currentMeeting.id);
      } else {
        this.router.navigate(['/event', this.eventID]);
      }
    });

    setInterval(() => {
      this.time = new Date();
      this.timeGMT = this.adjustForTimezone(new Date());
    }, 1000);

    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
  }

  openChat() {
    this.chatLayerVisability = true;
  }
  closeChat() {
    this.chatLayerVisability = false;
  }

  ngOnDestroy() {
    this.timerService.sendEndActivity(this.timerService.endTimer());
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VimeoUrlPipe } from './vimeo-url.pipe';
import { EventComponent } from './event/event.component';
import { BoothComponent } from './booth/booth.component';
import { AuditoriumComponent } from './auditorium/auditorium.component';
import { SupportComponent } from './support/support.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimerService } from './services/timer.service';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { TawkChatComponent } from './tawk-chat/tawk-chat.component';
import { LiveVimeoUrlPipe } from './live-vimeo-url.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { BoothsComponent } from './booths/booths.component';
import { RegisterComponent } from './register/register.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { EventService } from './services/event.service';
import { LoginComponent } from './login/login.component';
import { ExternalLinkComponent } from './external-link/external-link.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FooterComponent } from './footer/footer.component';
import { MobileComponent } from './mobile/mobile.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { LobbyChatModule } from './lobby-chat/lobby-chat.module';
import { CookieService } from 'ngx-cookie-service';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { ShowPasswordDirective } from './show-password.directive';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SlideMenuModule } from 'cuppa-ng2-slidemenu/cuppa-ng2-slidemenu';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AgendaComponent } from './agenda/agenda.component';
import { ToastrModule } from 'ngx-toastr';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MessagingService } from './messaging.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { GameComponent } from './game/game.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ExtraFooterComponent } from './extra-footer/extra-footer.component';

@NgModule({
  declarations: [
    AppComponent,
    VimeoUrlPipe,
    EventComponent,
    BoothComponent,
    AuditoriumComponent,
    SupportComponent,
    TawkChatComponent,
    LiveVimeoUrlPipe,
    HeaderComponent,
    BoothsComponent,
    RegisterComponent,
    LoginComponent,
    ExternalLinkComponent,
    ResetPasswordComponent,
    FooterComponent,
    MobileComponent,
    TermsAndConditionsComponent,
    FeedbackFormComponent,
    ShowPasswordDirective,
    AgendaComponent,
    GameComponent,
    GalleryComponent,
    ExtraFooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    HttpClientModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      // Infinity
      // backdropBackgroundColour: '#002d6a',
      // Meeting
      backdropBackgroundColour: '#242424',
      backdropBorderRadius: '2px',
      primaryColour: '#007396',
      secondaryColour: 'white',
      tertiaryColour: 'transparent',
    }),
    FontAwesomeModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    TooltipModule.forRoot(),
    LobbyChatModule,
    NgxUsefulSwiperModule,
    SlideMenuModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AngularFirestoreModule, // firestore
  ],
  providers: [TimerService, EventService, CookieService, MessagingService],
  bootstrap: [AppComponent],
})
export class AppModule {}

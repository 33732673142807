<app-header></app-header>


<div class="container mt-5">
    <div class="row mt-5">

        <h2 *ngIf="currentCompetition" class="w-100 mb-5"> Welcome to {{ currentCompetition.name }}</h2>

        <div class="col-md-6 col-12">
            <div *ngIf="activeQuestion && !alreadyParticipatedScore">

                <h3 class="mb-4 mt-4">{{ activeQuestion.title }}</h3>

                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let answer of activeQuestion.answers"
                        (click)="checkAnswer(answer, $event)">{{ answer | titlecase}}</li>
                </ul>

                <h1 class="w-100 text-center mt-3 display-2">{{ countDownTimer }}</h1>

            </div>


            <div class="w-100" *ngIf="result">
                <h1>Your Result is: <span class="text-success">{{ result }}</span> <br> Thank You For Participating</h1>
            </div>

        </div>
    </div>
</div>

<app-footer></app-footer>
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  {name: 'main', src: 'https://static.chatwee-api.com/v2/script.js'}, // embeddable
  {name: 'chatwee', src: 'https://chatwee-api.com/script/5ef0eea6a559034694271284.js'}, // html inject script
];

@Injectable({
  providedIn: 'root'
})
export class ChatweeService {

  private scripts: any = {};

  constructor(private http: HttpClient) {
    this.init();
  }

  init() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;

        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({script: name, loaded: true, status: 'Loaded'});
        };
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  login(userId) {
    return this.http.get('https://chatwee-api.com/v2/sso-user/login?chatId=' + environment.CHATWEE_ID + '&clientKey=' + environment.CLIENT_API_KEY + '&userId=' + userId);
  }

}

export enum ChatweeActions {
  open = 'open',
  close = 'close'
}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { ChatweeActions, ChatweeService } from "../services/chatwee.service";
import { map } from "rxjs/operators";
import { EventService } from 'src/app/services/event.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

declare var ChatweeLib;

@Component({
  selector: 'app-chatwee',
  templateUrl: './chatwee.component.html',
  styleUrls: ['./chatwee.component.scss']
})
export class ChatweeComponent implements OnInit, OnDestroy {
  @Input() chatweeActionsObservable: Observable<ChatweeActions>;
  @ViewChild('chatWindow') chatWindow: ElementRef;
  chatId = environment.CHATWEE_ID;
  clientApiKey = environment.CLIENT_API_KEY;
  sessionCookieKey = environment.SESSION_COOKIE_KEY;
  chatweeManager;
  chatLoaded$;
  opening = false;
  faTimes = faTimes;
  closing = false;
  subjectSubscription: Subscription;
  openChatSubjectSubscription: Subscription;
  chatLoaded = interval(100)
    .pipe(map(() => {
      return this.chatWindow.nativeElement.innerHTML.indexOf('embeddedChatweeWindow') === -1;
    }));

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      // console.log("clicked inside");
    } else {
      // console.log('clicked Outside');
      this.closeChatIfOpen();
    }
  }

  constructor(
    public chatweeService: ChatweeService,
    private hostElement: ElementRef,
    private eventService: EventService,
    private cookieService: CookieService,
    private eRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.chatweeService.load('main')
      .then(data => {
        this.chatweeManager = new ChatweeLib.ChatweeManager(this.chatId);
        this.chatweeManager.SetChatProperty('chatweeAutoLogin', true);

        // this.chatweeManager.SetChatProperty('chatweeLayout', 'embedded');
        // this.chatweeManager.SetChatProperty('chatweeEmbeddedSize', 'manual');
        // this.chatweeManager.SetChatProperty('chatweeDefaultEmbeddedWidth', '500');
        // this.chatweeManager.SetChatProperty('chatweeDefaultEmbeddedHeight', '500');
        // this.chatweeManager.Run();
        // console.log('loaded', data);
        // this.openChat();
      })
      .catch(error => console.log(error));

    this.subjectSubscription = this.eventService.closePublicChat.subscribe(response => {
      this.closeChat();
      // console.log('close cancelled');
    });

    this.openChatSubjectSubscription = this.eventService.openPublicChat.subscribe(response => {
      this.cookieService.set(environment.SESSION_COOKIE_KEY, localStorage.getItem('chatweeSession'));
      this.openChat();
    })

  }

  ngOnDestroy() {
    this.subjectSubscription.unsubscribe();
    this.openChatSubjectSubscription.unsubscribe();
    this.chatLoaded$.unsubscribe();
  }

  openChat() {
    this.chatTrigger(ChatweeActions.open);
  }

  closeChat() {
    this.chatTrigger(ChatweeActions.close);
  }

  chatTrigger(action: ChatweeActions) {
    switch (action) {
      case ChatweeActions.open:
        this.opening = true;
        this.cookieService.getAll();
        this.cookieService.set(environment.SESSION_COOKIE_KEY, localStorage.getItem('chatweeSession'));
        // document.cookie = environment.SESSION_COOKIE_KEY + "=" + localStorage.getItem('chatweeSession');
        this.chatLoaded$ = this.chatLoaded.subscribe((value) => {
          this.opening = value;
          if (!value) {
            this.chatLoaded$.unsubscribe();
          }
        });
        if (this.eventService.currentEvent.events.EnableChatWee) {
          console.log('Enable ChatWee');
          this.chatweeManager.Run();
        }
        break;
      case ChatweeActions.close:
        this.closing = true;
        this.chatLoaded$ = this.chatLoaded.subscribe((value) => {
          this.closing = !value;
          if (value) {
            this.chatLoaded$.unsubscribe();
          }
        });
        this.chatweeManager.Dispose();
        // console.log('Chatwee Cancelled');
        break;

    }
  }

  closeChatIfOpen() {
    // console.log('clicked Outside');
    if (this.eventService.PublicChatVisible) {
      document.getElementById("chatwee-widget").style.visibility = "hidden";
      document.getElementById("closeDiv").style.visibility = "hidden";
      this.eventService.PublicChatVisible = false;
    }
  }

}

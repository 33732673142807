import { Component, OnInit, Renderer2 } from '@angular/core';
import { GameService } from '../services/game.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {
  loader: boolean = true;
  currentCompetition: any;
  activeQuestion: any;
  observable: any;
  result: any;
  score: number = 0;
  countDownTimer: number = 5;
  countDownObservable: any;
  dashboardObservable: any;
  username: string;
  userMail: string;
  leaderBoard: any;
  alreadyParticipatedScore: number;

  constructor(
    private gameService: GameService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.gameService.getCompetition().subscribe((response: any) => {
      this.currentCompetition = response[0];
      console.log(this.currentCompetition);

      this.username = localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
      this.userMail = localStorage.getItem('email');

      this.getLeaderboard();

      this.startCountDown();

      this.activeQuestion = this.currentCompetition.questions[0];

      this.observable = interval(5000).subscribe((val) => {
        this.activeQuestion = this.currentCompetition.questions[val + 1];
        console.log(this.activeQuestion);
        if (val == Object.keys(this.currentCompetition.questions).length - 1) {
          this.getResult();
          this.endCountDown();
        }
      });

    })
  }

  checkAnswer(answer, event) {
    this.activeQuestion.answers = [answer]
    console.log(answer);
    if (answer.toLowerCase() == this.activeQuestion.rightAnswer.toLowerCase()) {
      this.score++;
      this.renderer.addClass(event.target, 'bg-success');
    } else {
      this.renderer.addClass(event.target, 'bg-danger');
    }
    console.log(this.score);
  }

  getResult() {
    this.observable.unsubscribe();
    this.result = this.score + ' out of ' + Object.keys(this.currentCompetition.questions).length;
  }

  submitResults() {

  }

  checkIfUserHasAnsweredBefore(mail) {

    console.log(this.leaderBoard);
    const outcome = this.leaderBoard.scoreboard.find(entry => entry.email.toLowerCase() == mail.toLowerCase());
    console.log(outcome);

    if (outcome) {

      outcome.score;
      this.score = outcome.score;
      this.getResult();

      return outcome.score
    } else {
      return false
    }
  }

  startCountDown() {
    this.countDownObservable = interval(1000).subscribe((val) => {
      this.countDownTimer--;
      if (this.countDownTimer == 0) { this.countDownTimer = 5 }
    })
  }

  resetCountDown() {
    this.countDownTimer = 5;
  }

  endCountDown() {
    this.countDownObservable.unsubscribe();
  }


  getLeaderboard() {
    this.dashboardObservable = this.gameService.getLeaderboard().subscribe((response: any) => {
      console.log(response[0]);
      this.leaderBoard = response[0];
      this.checkIfUserHasAnsweredBefore(this.userMail);
    })
  }

}
